import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter, Link } from "react-router-dom";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
} from "../../store/functions";

class pageAddEdit_GlobalOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
    };
    this.obj = this;
    this.formTitle = "Global Options";
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL_SAVE = API_WEB_URLS.GLOBALOPTIONS + "/0/token";
    this.pushFormName = "/GlobalOptions";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.CityFill = this.CityFill.bind(this);
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/GlobalOptions";
  }
  componentDidMount() {
    Fn_DisplayData(this.obj, 0, this.API_URL + "/Id",'State');
    Fn_FillListData(
      this.obj,
      "State",
      API_WEB_URLS.MASTER + "/0/token/State/Id/0"
    );
 
  }
  CityFill(event){
    const value = event.target.value
    Fn_FillListData(
      this.obj,
      "City",
      API_WEB_URLS.MASTER + "/0/token/City/F_StateMaster/" + value
    );
  }
  btnSave_onClick(event, formData) {
    Fn_AddEditData(
      this.obj,
      {
        arguList: {
          id: 0,
          firmName: formData.FirmName,
          address1: formData.Address1,
          address2: formData.Address2,
          f_CityMaster: formData.F_CityMaster,
          f_StateMaster: formData.F_StateMaster,
          mobileNo: formData.MobileNo,
          telephone: formData.Telephone,
          gst: formData.GST,
          pan: formData.PAN,
          bankAccountName: formData.BankAccountName,
          bankName: formData.BankName,
          accountNo: formData.AccountNo,
          ifsc: formData.IFSC,
          bankAccountName2: formData.BankAccountName2,
          bankName2: formData.BankName2,
          accountNo2: formData.AccountNo2,
          ifsC2: formData.IFSC2,
          isSaleInvoiceAuto: formData.IsSaleInvoiceAuto,
          alias: formData.Alias,
          separator: formData.Separator,
          VoucherNoStart: formData.VoucherNoStart,
          IsVoucherNoEnable: formData.IsVoucherNoEnable,
          CNStartNo: formData.CNStartNo,
          DNStartNo: formData.DNStartNo,
          IsDirectPrint: formData.IsDirectPrint,
          RoundTo5Paisa: formData.RoundTo5Paisa,
          BackgroundColor: formData.BackgroundColor,
          TextColor: formData.TextColor,
          PrintSootType: formData.PrintSootType,
          Email: formData.Email,
          EwayBillStartAmount: formData.EwayBillStartAmount,
          EInvoiceUserName: formData.EInvoiceUserName,
          EInvoicePassword: formData.EInvoicePassword,
          PinCode: formData.PinCode,
          IsActualSoot: formData.IsActualSoot,
        },
      },
      this.API_URL_SAVE,
      this.pushFormName
    );
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <Row>
                            <Col lg="12">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="firstName"
                                        className="col-form-label"
                                      >
                                        Firm Name
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="FirmName"
                                        label=""
                                        value={
                                          this.state.formData.FirmName === null
                                            ? ""
                                            : this.state.formData.FirmName
                                        }
                                        placeholder="Firm Name "
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="middleName"
                                        className="col-form-label"
                                      >
                                        Address1
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <AvField
                                        name="Address1"
                                        label=""
                                        value={
                                          this.state.formData.Address1 === null
                                            ? ""
                                            : this.state.formData.Address1
                                        }
                                        placeholder="Address1"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        Address2
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <AvField
                                        name="Address2"
                                        label=""
                                        value={
                                          this.state.formData.Address2 === null
                                            ? ""
                                            : this.state.formData.Address2
                                        }
                                        placeholder="Address2"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        PinCode
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="PinCode"
                                        label=""
                                        value={
                                          this.state.formData.PinCode === null
                                            ? ""
                                            : this.state.formData.PinCode
                                        }
                                        placeholder="PinCode"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="maidenName"
                                        className="col-form-label"
                                      >
                                        State
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="F_StateMaster"
                                        label=""
                                        value={
                                          this.state.formData.F_StateMaster ===
                                          null
                                            ? "-1"
                                            : this.state.formData.F_StateMaster
                                        }
                                        type="select"
                                        className="form-select"
                                        onChange={this.CityFill}
                                      >
                                        <option
                                          value={-1}
                                          defaultValue
                                          label={"Select"}
                                        />
                                        {this.state.State
                                          ? this.state.State.map(
                                              (option, key) => (
                                                <option
                                                  key={option.Id}
                                                  value={option.Id}
                                                  label={option.Name}
                                                />
                                              )
                                            )
                                          : null}
                                      </AvField>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="maidenName"
                                        className="col-form-label"
                                      >
                                        City
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="F_CityMaster"
                                        label=""
                                        value={
                                          this.state.formData.F_CityMaster ===
                                          null
                                            ? "-1"
                                            : this.state.formData.F_CityMaster
                                        }
                                        type="select"
                                        className="form-select"
                                      >
                                        <option
                                          value={-1}
                                          defaultValue
                                          label={"Select"}
                                        />
                                        {this.state.City
                                          ? this.state.City.map(
                                              (option, key) => (
                                                <option
                                                  key={option.Id}
                                                  value={option.Id}
                                                  label={option.Name}
                                                />
                                              )
                                            )
                                          : null}
                                      </AvField>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        MobileNo
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="MobileNo"
                                        label=""
                                        value={
                                          this.state.formData.MobileNo === null
                                            ? ""
                                            : this.state.formData.MobileNo
                                        }
                                        placeholder="MobileNo"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        Telephone
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="Telephone"
                                        label=""
                                        value={
                                          this.state.formData.Telephone === null
                                            ? ""
                                            : this.state.formData.Telephone
                                        }
                                        placeholder="Telephone"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        GST
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="GST"
                                        label=""
                                        value={
                                          this.state.formData.GST === null
                                            ? ""
                                            : this.state.formData.GST
                                        }
                                        placeholder="GST"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        PAN
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="PAN"
                                        label=""
                                        value={
                                          this.state.formData.PAN === null
                                            ? ""
                                            : this.state.formData.PAN
                                        }
                                        placeholder="PAN"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        BankAccountName
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="BankAccountName"
                                        label=""
                                        value={
                                          this.state.formData
                                            .BankAccountName === null
                                            ? ""
                                            : this.state.formData
                                                .BankAccountName
                                        }
                                        placeholder="BankAccountName"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        BankName
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="BankName"
                                        label=""
                                        value={
                                          this.state.formData.BankName === null
                                            ? ""
                                            : this.state.formData.BankName
                                        }
                                        placeholder="BankName"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        AccountNo
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="AccountNo"
                                        label=""
                                        value={
                                          this.state.formData.AccountNo === null
                                            ? ""
                                            : this.state.formData.AccountNo
                                        }
                                        placeholder="AccountNo"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        IFSC
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="IFSC"
                                        label=""
                                        value={
                                          this.state.formData.IFSC === null
                                            ? ""
                                            : this.state.formData.IFSC
                                        }
                                        placeholder="IFSC"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        BankAccountName2
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="BankAccountName2"
                                        label=""
                                        value={
                                          this.state.formData
                                            .BankAccountName2 === null
                                            ? ""
                                            : this.state.formData
                                                .BankAccountName2
                                        }
                                        placeholder="BankAccountName2"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        BankName2
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="BankName2"
                                        label=""
                                        value={
                                          this.state.formData.BankName2 === null
                                            ? ""
                                            : this.state.formData.BankName2
                                        }
                                        placeholder="BankName2"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        AccountNo2
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="AccountNo2"
                                        label=""
                                        value={
                                          this.state.formData.AccountNo2 ===
                                          null
                                            ? ""
                                            : this.state.formData.AccountNo2
                                        }
                                        placeholder="AccountNo2"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        IFSC2
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="IFSC2"
                                        label=""
                                        value={
                                          this.state.formData.IFSC2 === null
                                            ? ""
                                            : this.state.formData.IFSC2
                                        }
                                        placeholder="IFSC2"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        IsSaleInvoiceAuto
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="IsSaleInvoiceAuto"
                                        label=""
                                        checked={
                                          this.state.formData
                                            .IsSaleInvoiceAuto === null
                                            ? false
                                            : this.state.formData
                                                .IsSaleInvoiceAuto
                                        }
                                        type="checkbox"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        Alias
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="Alias"
                                        label=""
                                        value={
                                          this.state.formData.Alias === null
                                            ? ""
                                            : this.state.formData.Alias
                                        }
                                        placeholder="Alias"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        Separator
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="Separator"
                                        label=""
                                        value={
                                          this.state.formData.Separator === null
                                            ? ""
                                            : this.state.formData.Separator
                                        }
                                        placeholder="Separator"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        Voucher No Start
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="VoucherNoStart"
                                        label=""
                                        value={
                                          this.state.formData.VoucherNoStart ===
                                          null
                                            ? ""
                                            : this.state.formData.VoucherNoStart
                                        }
                                        placeholder="VoucherNoStart"
                                        type="number"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        Credit Note No Start
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="CNStartNo"
                                        label=""
                                        value={
                                          this.state.formData.CNStartNo === null
                                            ? ""
                                            : this.state.formData.CNStartNo
                                        }
                                        placeholder="CNStartNo"
                                        type="number"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        Debit Note No Start
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="DNStartNo"
                                        label=""
                                        value={
                                          this.state.formData.DNStartNo === null
                                            ? ""
                                            : this.state.formData.DNStartNo
                                        }
                                        placeholder="DNStartNo"
                                        type="number"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        IsInvoiceNoDisable
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="IsVoucherNoEnable"
                                        label=""
                                        checked={
                                          this.state.formData
                                            .IsVoucherNoEnable === null
                                            ? false
                                            : this.state.formData
                                                .IsVoucherNoEnable
                                        }
                                        type="checkbox"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        IsDirectPrint
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="IsDirectPrint"
                                        label=""
                                        checked={
                                          this.state.formData.IsDirectPrint ===
                                          null
                                            ? false
                                            : this.state.formData.IsDirectPrint
                                        }
                                        type="checkbox"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        RoundTo5Paisa
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="RoundTo5Paisa"
                                        label=""
                                        checked={
                                          this.state.formData.RoundTo5Paisa ===
                                          null
                                            ? false
                                            : this.state.formData.RoundTo5Paisa
                                        }
                                        type="checkbox"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        IsActualSoot
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="IsActualSoot"
                                        label=""
                                        checked={
                                          this.state.formData.IsActualSoot ===
                                          null
                                            ? false
                                            : this.state.formData.IsActualSoot
                                        }
                                        type="checkbox"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        BackgroundColor
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="BackgroundColor"
                                        label=""
                                        value={
                                          this.state.formData
                                            .BackgroundColor === null
                                            ? ""
                                            : this.state.formData
                                                .BackgroundColor
                                        }
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        TextColor
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="TextColor"
                                        label=""
                                        value={
                                          this.state.formData.TextColor === null
                                            ? ""
                                            : this.state.formData.TextColor
                                        }
                                        placeholder="TextColor"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="maidenName"
                                        className="col-form-label"
                                      >
                                        PrintSootType
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="PrintSootType"
                                        label=""
                                        value={
                                          this.state.formData.PrintSootType ===
                                          null
                                            ? "-1"
                                            : this.state.formData.PrintSootType
                                        }
                                        type="select"
                                        className="form-select"
                                      >
                                        <option
                                          value={-1}
                                          defaultValue
                                          label={"Select"}
                                        />
                                        <option
                                          value={1}
                                          defaultValue
                                          label={"Soot"}
                                        />
                                        <option
                                          value={2}
                                          defaultValue
                                          label={"Fraction"}
                                        />
                                        <option
                                          value={3}
                                          defaultValue
                                          label={"Decimal"}
                                        />
                                      </AvField>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        Email
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="Email"
                                        label=""
                                        value={
                                          this.state.formData.Email === null
                                            ? ""
                                            : this.state.formData.Email
                                        }
                                        placeholder="Email"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        EInvoice UserName
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="EInvoiceUserName"
                                        label=""
                                        value={
                                          this.state.formData
                                            .EInvoiceUserName === null
                                            ? ""
                                            : this.state.formData
                                                .EInvoiceUserName
                                        }
                                        placeholder="EInvoice UserName"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>

                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        EInvoice Password
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="EInvoicePassword"
                                        label=""
                                        value={
                                          this.state.formData
                                            .EInvoicePassword === null
                                            ? ""
                                            : this.state.formData
                                                .EInvoicePassword
                                        }
                                        placeholder="EInvoice Password"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        EwayBillStartAmount
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="EwayBillStartAmount"
                                        label=""
                                        value={
                                          this.state.formData
                                            .EwayBillStartAmount === null
                                            ? ""
                                            : this.state.formData
                                                .EwayBillStartAmount
                                        }
                                        placeholder="EwayBillStartAmount"
                                        type="number"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          <Row></Row>

                          <div className="d-flex flex-wrap gap-2">
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                            >
                              Update
                            </Button>

                            <Button
                              type="button"
                              color="secondary"
                              className="waves-effect"
                              onClick={this.btnCancel_onClick}
                            >
                              Cancel
                            </Button>

                            {this.state.success_msg ? (
                              <SweetAlert
                                title="Customer Saved Successfully!"
                                success
                                confirmBtnBsStyle="success"
                                onConfirm={this.syno}
                              >
                                You clicked the button!
                              </SweetAlert>
                            ) : null}
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_GlobalOptions);
