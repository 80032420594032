import React, { useRef } from "react";

function TableRowsPly({ rowsData, add, ply, edit, del }) {
  const F_PlyPartys = useRef([]);
  const rat = useRef([]);
  const adds = useRef([]);

  const handleFocus = event => event.target.select();

  const handler2 = (index, evnt, indo) => {
    const rowsInput = [...rowsData];

    if (evnt.key == "Enter") {
      if (index == "Rate") rat.current[indo].focus();
      else if (index == "F_PlyParty") F_PlyPartys.current[indo].focus();
      else if (index == "Adds") adds.current[indo].focus();

      evnt.preventDefault();
    }
  };

  return rowsData.map((data, index) => {
    const { Id, F_PlyParty, Rate } = data;
    return (
      <tr key={index}>
        <td>
          {" "}
          <select
            ref={el => (F_PlyPartys.current[index] = el)}
            style={{ width: "200px" }}
            onChange={evnt => edit(index, evnt)}
            onKeyPress={evnt => handler2("Rate", evnt, index)}
            value={F_PlyParty}
            name="F_PlyParty"
            className="form-select"
          >
            <option value={0} defaultValue label={"Ply Desc..."} />
            {ply
              ? ply.map((option, key) => (
                  <option
                    key={option.Id}
                    value={option.Id}
                    label={option.Name}
                  />
                ))
              : null}
          </select>
        </td>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <td>
          <input
            ref={el => (rat.current[index] = el)}
            style={{ width: "100px" }}
            className="form-control"
            type="text"
            name="Rate"
            value={Rate}
            onFocus={handleFocus}
            onChange={evnt => edit(index, evnt)}
            onKeyPress={evnt => handler2("Adds", evnt, index)}
          />
        </td>
        <td>
          <button
            ref={el => (adds.current[index] = el)}
            onKeyPress={evnt => handler2("F_PlyParty", evnt, index + 1)}
            type="button"
            className="btn btn-outline-success"
            onClick={add}
          >
            +
          </button>
        </td>
        <td>
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={() => del(Id, index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}
export default TableRowsPly;
