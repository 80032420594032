import { API_WEB_URLS } from "constants/constAPI";
import { element } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";

function TableRows_new({
  rowsData,
  deleteTableRows,
  handleChange,
  itemName,
  GroupMaster,
  UnitMaster,

  addTableRows,
  plyquantity,
  TaxName,
  CustomerId,
}) {
  const [focusIndex, setFocusIndex] = useState(null);
  const Code = useRef([]);
  const Groups = useRef([]);
  const Items = useRef([]);
  const Units = useRef([]);

 

  const Qtys = useRef([]);

  const Rates = useRef([]);
  const Mrps = useRef([]);
  const TaxNames = useRef([]);
  const Discs = useRef([]);

  const Amounts = useRef([]);
  const Adds = useRef([]);
  const Deletes = useRef([]);
 
  useEffect(() => {
    // if (focusIndex !== null) {
    //   Code.current[focusIndex].focus();
    //   setFocusIndex(null); 
    // }
  }, [rowsData, focusIndex]);
  const handleFocus = event => event.target.select();
  const handler = (name, event, index) => {
    if (event.key === "Enter") {
      event.preventDefault();

      setTimeout(() => {
        switch (name) {
          case "Code":
            if (Code.current[index]) {
              Code.current[index].focus();
            }
            break;
          case "Items":
            if (Items.current[index]) {
              Items.current[index].focus();
            }
            break;
          case "Groups":
            if (Groups.current[index]) {
              Groups.current[index].focus();
            }
            break;
          case "Units":
            if (Units.current[index]) {
              Units.current[index].focus();
            }
            break;
          case "Qtys":
            if (Qtys.current[index]) {
              Qtys.current[index].focus();
            }
            break;
          case "Rates":
            if (Rates.current[index]) {
              Rates.current[index].focus();
            }
            break;
          case "Mrps":
            if (Mrps.current[index]) {
              Mrps.current[index].focus();
            }
            break;
          case "Amounts":
            if (Amounts.current[index]) {
              Amounts.current[index].focus();
            }
            break;
          case "TaxNames":
            if (TaxNames.current[index]) {
              TaxNames.current[index].focus();
            }
            break;
          case "Discs":
            if (Discs.current[index]) {
              Discs.current[index].focus();
            }
            break;
          case "Amounts":
            if (Amounts.current[index]) {
              Amounts.current[index].focus();
            }
            break;
      
          case "Adds":
            if (Adds.current[index]) {
              addTableRows()

              Adds.current[index].focus();
            }
            break;
          case "Deletes":
            if (Deletes.current[index]) {
            

              Deletes.current[index].focus();
            }
            break;
          default:
            break;
        }
      }, 0);
    }
  };
    
  // const handler2 = (index, evnt, indo) => {
  //   if (evnt.key == "Enter" ) {
  //     const rowsInput = [...rowsData];

    
    
  //     if (index === "Code") {

  //       addTableRows();
  //       if (indo === rowsData.length - 1) {
        
  //         setFocusIndex(indo); 
          
  //       }
  //     }
      
      
  //       if (index == "Items") Items.current[indo].focus();
  //       if (index == "Groups") Groups.current[indo].focus();
  //       if (index == "Units") Units.current[indo].focus();
  //       if (index == "Qtys") Qtys.current[indo].focus();
  //       else if (index == "Rates") Rates.current[indo].focus();
  //       else if (index == "Mrps") Mrps.current[indo].focus();
  //       else if (index == "Amounts") Amounts.current[indo].focus();
  //       else if (index == "TaxNames") TaxNames.current[indo].focus();
  //       else if (index == "Discs") Discs.current[indo].focus();
  //       else if (index == "Amounts") Amounts.current[indo].focus();
  //       else if (index == "Adds") {
  //         Adds.current[indo].focus(); 
         
  //       }
  //       else if (index == "Deletes") Deletes.current[indo].focus();
     
 
  //     evnt.preventDefault();
  //   }
  // };
  return rowsData.map((data, index) => {
    

    const {
      IsDirectInvoice,
      CodeNo,
      ItemName,
      F_GroupMaster,
      Qty,
      F_UnitMaster,
      Rate,
      Disc,
      Amount,
      TaxName,
 
    } = data;
    return (
      <tr key={index}>
        <td>{index + 1}</td>

        {/* <td>
          <input
            onKeyPress={evnt => handler2("Code", evnt, index)}
            type="checkbox"
            name="IsDirectInvoice"
            checked={IsDirectInvoice}
          ></input>
        </td> */}
        <td>
          <input
             onFocus={handleFocus}
            className="form-control"
            type="text"
            style={{ width: "60px", height: "36px" }}
            name="CodeNo"
            //  ref ={Code[index]}
            ref={el => (Code.current[index] = el)}
            onKeyPress={evnt => handler("Groups", evnt, index)}
            value={CodeNo}
            onChange={evnt => handleChange(index, evnt)}
          />{" "}
        </td>

        <td>
          <select
            style={{ width: "210px" }}
            ref={el => (Groups.current[index] = el)}
            onKeyPress={evnt => handler("Items", evnt, index)}
            value={F_GroupMaster}
            name="F_GroupMaster"
            onChange={evnt => {handleChange(index, evnt); Items.current[index].focus()}}
            className="form-select"
          >
            <option value={"-1"} defaultValue label={"Select"} />
            {GroupMaster
              ? GroupMaster.map((option, key) => (
                  <option
                    key={option.Id}
                    
                    value={option.Id}
                    label={option.Name}
                  />
                ))
              : null}
          </select>
        </td>
        <td>
          <select
            style={{ width: "210px" }}
            ref={el => (Items.current[index] = el)}
            onKeyPress={evnt => handler("Units", evnt, index)}
            value={ItemName}
            name="ItemName"
            onChange={evnt => {handleChange(index, evnt); Units.current[index].focus()}}
            className="form-select"
          >
            <option value={"-1"} defaultValue label={"Select"} />
            {itemName
              ? itemName[index] &&  itemName[index].map((option, key) => (
                  <option
                    key={option.Id}
                    id={option.IGST}
                    value={option.Id}
                    label={option.ItemName}
                  />
                ))
              : null}
          </select>
        </td>
        <td>
          <select
            style={{ width: "80px",padding:0, height: "36px" }}
            ref={el => (Units.current[index] = el)}
            onKeyPress={evnt => handler("Qtys", evnt, index)}
            value={F_UnitMaster}
            name="F_UnitMaster"
            onChange={evnt => {handleChange(index, evnt); Qtys.current[index].focus()}}
            className="form-select"
          >
            <option value={"-1"} defaultValue label={"Select"} />
            {UnitMaster
              ? UnitMaster.map((option, key) => (
                  <option
                    key={option.Id}
                    
                    value={option.Id}
                    label={option.Name}
                  />
                ))
              : null}
          </select>
        </td>

        <td>
          <input
          onFocus={handleFocus}
            style={{ width: "50px", height: "36px" }}
            ref={el => (Qtys.current[index] = el)}
            onKeyPress={evnt => handler("Discs", evnt, index)}
            className="form-control "
            type="text"
            name="Qty"
           
            value={Qty}
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

      
        <td>
          <input
              onFocus={handleFocus}
            style={{ width: "60px", height: "36px" }}
            ref={el => (Rates.current[index] = el)}
            onKeyPress={evnt => handler("Discs", evnt, index)}
            className="form-control "
            type="text"
            value={Rate}
            name="Rate"
            onChange={evnt => handleChange(index, evnt)}
         
          />
        </td>
        <td>
          <input
           onFocus={handleFocus}
            style={{ width: "40px", height: "36px" }}
            ref={el => (Discs.current[index] = el)}
            onKeyPress={evnt => handler("Deletes", evnt, index)}
            className="form-control "
            type="text"
            value={Disc}
            name="Disc"
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

        <td>
          <input
          
            style={{ width: "80px", height: "36px" }}
            ref={el => (Amounts.current[index] = el)}
            onKeyPress={evnt => handler("TaxNames", evnt, index)}
            className="form-control "
            type="text"
            value={Amount}
            name="Amount"
            onChange={evnt => handleChange(index, evnt)}
            disabled
          />
        </td>
        <td>
        <input
         autoFocus
            style={{ width: "80px", height: "36px", padding:0 }}
            ref={el => (TaxNames.current[index] = el)}
            onKeyPress={evnt => handler("Deletes", evnt, index)}
            className="form-control "
            type="text"
            value={TaxName}
            name="TaxName"
            disabled
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>
        <td>
          <button
            ref={el => (Deletes.current[index] = el)}
            onKeyPress={evnt => handler("Adds", evnt, index)}
            className="btn btn-outline-danger"
            type="button"
            onClick={() => deleteTableRows(index)}
          >
            x
          </button>
        </td>
        <td>
          <button
            ref={el => (Adds.current[index] = el)}
            onKeyPress={evnt => handler("Code", evnt, index + 1)}
            type="button"
            className="btn btn-outline-success"
            
          >
            +
          </button>
        </td>
      </tr>
    );
  });
}
export default TableRows_new;
