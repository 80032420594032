import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Constants
import { API_WEB_URLS } from "../constants/constAPI";
import * as base_acTypes from "./actionTypes";
import * as XLSX from 'xlsx';
/** Common Functions **/
//Change state value
export const Fn_ChangeStateValue = (obj, name, value) => {
  obj.setState({ [name]: value });
};

export const Fn_FillListData = (obj, gridName, apiURL, index, Id) => {
  return new Promise((resolve, reject) => {
    const request = {
      apiURL: apiURL,
      callback: response => {
        if (response && response.status === 200 && response.data) {
          obj.setState({
            [gridName]: response.data.dataList,
            isProgress: false,
          });
          console.log(gridName);
          if (gridName == "gridDataSearch") {
           
            const firstObject = response.data.dataList[0];
            const keysArray = Object.keys(firstObject).filter((item)=>item !== 'Id');

            obj.setState({ gridData : response.data.dataList,filteredData :response.data.dataList, SearchKeyArray: keysArray, searchKey : keysArray[0] });
          }
          if(gridName==='State1'){
            Fn_FillListData(
              this.obj,
              "City",
              API_WEB_URLS.MASTER + "/0/token/City/F_StateMaster/" + response.data.dataList[0].Id
            );
          }
          if (gridName === "ItemNames1") {
            obj.replaceSubArray(obj.state.ItemName, index, response.data.dataList, Id);
          }
          if (gridName === "ItemNames2") {
            obj.addSubArray(obj.state.ItemName, response.data.dataList, index, Id);
          }
          if (gridName === "ItemName1") {
            obj.replaceSubArray(obj.state.ItemName, index, response.data.dataList);
          }
          if (gridName === "ItemName2") {
            console.log("in Itemname2");
            obj.addSubArray(obj.state.ItemName, response.data.dataList);
          }
          if (gridName === "InvoiceDataL") {
            obj.setState({
              InvoiceDataL: response.data.dataList,
              isProgress: false,
            }, async () => {
              console.log("dataList", response.data.dataList);
              for (const item of response.data.dataList) {
                console.log("dataList", response.data.dataList, item.F_GroupMaster);
                await Fn_FillListData(
                  obj,
                  "ItemName2",
                  API_WEB_URLS.MASTER + "/0/token/ItemType/TBL.F_GroupMaster/" + item.F_GroupMaster
                );
              }
              resolve(); // Resolve the promise after processing all items
            });
            return; // Return early since we are handling setState asynchronously
          }
          if (gridName === "PlyPartyH") {
            obj.setState({
              IsCalculated: response.data.dataList[0].Calculated === true,
            });
          }
          
          resolve(); // Resolve the promise after processing
        } else {
          showToastWithCloseButton(
            "error",
            "Some error occurred while displaying grid data"
          );
          reject(new Error("Some error occurred while displaying grid data"));
        }
      },
    };
    obj.props.callFill_GridData(request);
  });
};

export  const Fn_FillItemName = async (obj,arr)=>{
  for (const item of arr) {
        
    await Fn_FillListData(
      obj,
      "ItemName2",
      API_WEB_URLS.MASTER + "/0/token/ItemType/TBL.F_GroupMaster/" + item.F_GroupMaster
    );
  }
}

export const Fn_FillListDataLedger = (obj, gridName, apiURL) => {
  const request = {
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [gridName]: response.data.dataList,
          isProgress: false,
          Name: response.data.dataList[0].Name,
          GSTIN: response.data.dataList[0].GSTNo,
          PhoneNo: response.data.dataList[0].Phone,
          Mobile: response.data.dataList[0].Mobile,
          Email: response.data.dataList[0].Email,
          Address1: response.data.dataList[0].Address1,
          Address2: response.data.dataList[0].Address2,
          ZIP: response.data.dataList[0].Zip,
          CountryId: response.data.dataList[0].F_CountryMaster,
          StateId: response.data.dataList[0].F_StateMaster,
          CityId: response.data.dataList[0].F_CityMaster,
          LedgerGroupIds: response.data.dataList[0].F_LedgerGroupMaster,
        });
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };
  obj.props.callFill_GridData(request);
};

export const Fn_FillListDataGlobal = (obj, gridName, apiURL) => {
  const request = {
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [gridName]: response.data.dataList,
          VoucherNo: response.data.dataList[0].VoucherNo,
          isProgress: false,
        });

        if (response.data.dataList[0].IsVoucherNoEnable == true) {
          obj.vdate.current.focus();
        } else {
          obj.vno.current.focus();
        }
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };
  obj.props.callFill_GridData(request);
};

export const Fn_FillListData2 = (obj, gridName, apiURL) => {
  const request = {
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState(
          {
            [gridName]: response.data.dataList,
            isProgress: false,
            InvoiceId: response.data.dataList[0].Id,
            VoucherNo: response.data.dataList[0].VoucherNo,
            VoucherDate: response.data.dataList[0].VoucherDate,
            PartyDetail: response.data.dataList[0].PartyDetail,
            DiscountType: response.data.dataList[0].DiscountType,
            Rate: response.data.dataList[0].Rate,
            Info: response.data.dataList[0].Info,
            EntryMode: response.data.dataList[0].EntryMode,
            TotalQuantity: response.data.dataList[0].TotalQty,

            GrossAmount: response.data.dataList[0].GrossAmount,
            TaxAmount: response.data.dataList[0].TaxationAmount,
            NetAmount: response.data.dataList[0].NetAmount,
            LedgerId: response.data.dataList[0].F_LedgerMaster,
          }

          // Fn_FillListData3(obj , response.data.dataList[0].F_LedgerMaster , ""),
          // Fn_FillListData4(obj , response.data.dataList[0].F_LedgerMaster , ""),
          // Fn_FillListData5(obj , response.data.dataList[0].F_LedgerMaster , ""),
          // Fn_FillListData(obj, "PlyPartyH", API_WEB_URLS.MASTER + "/0/token/PlyPartyH/Id/"+response.data.dataList[0].F_LedgerMaster)
        );
        if (response.data.dataList[0].PurVoucherNo != null) {
          obj.setState({
            PurVoucherNo: response.data.dataList[0].PurVoucherNo,
            PurVoucherDate: response.data.dataList[0].PurVoucherDate,
          });
        }
        if (response.data.dataList[0].OpeningStock != null) {
          console.log(response.data.dataList[0].OpeningStock);
          obj.setState({
            OpeningStock: response.data.dataList[0].OpeningStock,
          });
        }
        if (response.data.dataList[0].IsCancelled == true) {
          obj.setState({
            canceleinvoiceBtn: true,
            cancelinvoiceName: "Already Cancelled!",
          });
        } else {
          obj.setState({
            canceleinvoiceBtn: false,
            cancelinvoiceName: "Cancel Invoice",
          });
        }

        if (response.data.dataList[0].Irn != null) {
          obj.setState({
            edit: true,
            editbtnname: "Cant Edit!",
            einvoice: true,
          });
        } else {
          obj.setState({ edit: false, editbtnname: "EDIT", einvoice: false });
        }

        if (response.data.dataList[0].IsDiff == true) {
          obj.setState({
            IsCon: false,
          });
        }
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };

  obj.props.callFill_GridData(request);
};

export const Fn_FillListData3 = (obj, F_LedgerMaster, apiURL) => {
  var Name = "Ply";

  const request2 = {
    apiURL: "Masters/0/token/GetItemByPlyPartyDescription/Id/" + F_LedgerMaster,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [Name]: response.data.dataList,
        });
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };

  obj.props.callFill_GridData(request2);
};

export const Fn_FillListDataInvoice = (obj, apiURL) => {
  var Name = "Invoice";

  const request2 = {
    apiURL: "Masters/0/token/"+ apiURL  +"/Id/0",
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [Name]: response.data.dataList,
        });
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };

  obj.props.callFill_GridData(request2);
};

export const Fn_FillListData4 = (obj, F_LedgerMaster, apiURL) => {
  var Name = "PrintDesc";

  const request2 = {
    apiURL: "Masters/0/token/GetPrintPartyDescription/Id/" + F_LedgerMaster,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [Name]: response.data.dataList,
        });
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };

  obj.props.callFill_GridData(request2);
};

export const Fn_FillListData5 = (obj, F_LedgerMaster, apiURL) => {
  var Name = "ConsigneeAddress";

  const request2 = {
    apiURL: "Masters/0/token/LedgerConsigneeAddress/Id/" + F_LedgerMaster,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [Name]: response.data.dataList,
        });

        if (obj.state.Add1 == true) {
          obj.setState({
            Add1: true,
            OtherAddress: response.data.dataList[0].Address1,
            Add2: false,
            Add3: false,
          });
        } else if (obj.state.Add2 == true) {
          obj.setState({
            Add1: false,
            OtherAddress: response.data.dataList[0].Address2,
            Add2: true,
            Add3: false,
          });
        } else if (obj.state.Add3 == true) {
          obj.setState({
            Add1: false,
            OtherAddress: response.data.dataList[0].Address3,
            Add2: false,
            Add3: true,
          });
        }
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };

  obj.props.callFill_GridData(request2);
};

export const Fn_DisplayData = (obj, id, apiURL,gridname) => {
  const request = {
    id: id,
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          formData: response.data.dataList[0],
        });
        if(gridname==='State'){
          Fn_FillListData(
            obj,
            "City",
            API_WEB_URLS.MASTER + "/0/token/City/F_StateMaster/" + response.data.dataList[0].F_StateMaster
          );
        }
        // if(apiURL===API_WEB_URLS.COMPANY)
        // {
        //     //Fill city drop down
        //     if(response.data.data[0].stateId && response.data.data[0].stateId>0)
        //         Fn_FillListData(obj,'lstCity',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/cities/stateId/'+ response.data.data[0].stateId);
        // }
        // else if(apiURL===API_WEB_URLS.PROPERTY_FLOOR || apiURL===API_WEB_URLS.SUB_COMPANY)
        // {
        //     //Fill city drop down
        //     if(response.data.data[0].blockId && response.data.data[0].blockId>0)
        //         Fn_FillListData(obj,'lstBlock',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/blocks/propertyId/'+ response.data.data[0].propertyId);
        // }
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying data"
        );
      }
    },
  };
  obj.props.callGet_Data(request);
};

export const Fn_DisplayDataLedger = (obj, id, apiURL) => {
  const request = {
    id: id,
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          formData: response.data.dataList[0],
          Name: response.data.dataList[0].Name,
          GSTIN: response.data.dataList[0].GSTNo,
          PhoneNo: response.data.dataList[0].Phone,
          Mobile: response.data.dataList[0].Mobile,
          Email: response.data.dataList[0].Email,
          Address1: response.data.dataList[0].Address1,
          Address2: response.data.dataList[0].Address2,
          ZIP: response.data.dataList[0].Zip,
          CountryId: response.data.dataList[0].F_CountryMaster,
          StateId: response.data.dataList[0].F_StateMaster,
          CityId: response.data.dataList[0].F_CityMaster,
          LedgerGroupIds: response.data.dataList[0].F_LedgerGroupMaster,
        });
        // if(apiURL===API_WEB_URLS.COMPANY)
        // {
        //     //Fill city drop down
        //     if(response.data.data[0].stateId && response.data.data[0].stateId>0)
        //         Fn_FillListData(obj,'lstCity',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/cities/stateId/'+ response.data.data[0].stateId);
        // }
        // else if(apiURL===API_WEB_URLS.PROPERTY_FLOOR || apiURL===API_WEB_URLS.SUB_COMPANY)
        // {
        //     //Fill city drop down
        //     if(response.data.data[0].blockId && response.data.data[0].blockId>0)
        //         Fn_FillListData(obj,'lstBlock',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/blocks/propertyId/'+ response.data.data[0].propertyId);
        // }
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying data"
        );
      }
    },
  };
  obj.props.callGet_Data(request);
};

export const Fn_ExportExcel = (obj,data,apiURL,filename,isMultiPart=false) => {
  const { arguList } = data;
  const request = {
      arguList: arguList,
      apiURL: apiURL,
      callback: (response) => {
          if (response && response.status === 200 && response.data) {

              // const filename  =  filename;

              if (filename == "Kotak Export"){
                  const worksheet = XLSX.utils.json_to_sheet(response.data.response, { skipHeader: true });
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                  XLSX.writeFile(workbook, `${filename}.xlsx`);
              }
              else {
              const worksheet = XLSX.utils.json_to_sheet(response.data.response);
              const workbook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
              XLSX.writeFile(workbook, `${filename}.xlsx`);
              }


          } else {
              showToastWithCloseButton("error", "Some error occurred.");
          }
      },
  };
  obj.props.callAdd_Data_Multipart(request);
  obj.setState({loading: false});

}

export const Fn_GetReport = (
  obj,
  data,
  apiURL,
  gridName,
  index,
  isMultiPart = false
) => {
  const { arguList } = data;
  const request = {
    arguList: arguList,
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        // obj.setState({
        //     [gridName]: response.data.response,
        //     isProgress : false
        // })
        if (gridName === "gridDataSearch") {
          const firstObject = response.data.response[0];
          const keysArray = Object.keys(firstObject).filter((item)=>item !== 'Id');

          obj.setState({ gridData : response.data.response ,filteredData :response.data.response, SearchKeyArray: keysArray, searchKey : keysArray[0] });
        }
        if (gridName == "kk") {
          const rowsInput = [...obj.state.InvoiceDataL];

          rowsInput[index]["Rate"] = response.data.response[0].Result;
          obj.setState({ InvoiceDataL: rowsInput });
        }
        if (gridName == "CodeArr") {
          const rowsInput = [...obj.state.InvoiceDataL];
          const groupVal = rowsInput[index]["F_GroupMaster"];
          rowsInput[index]["F_GroupMaster"] =
            response.data.response[0].F_GroupMaster;

          obj.setState({ InvoiceDataL: rowsInput });
          if (groupVal != null && groupVal != "" && groupVal != undefined) {
            Fn_FillListData(
              obj,
              "ItemNames1",
              API_WEB_URLS.MASTER +
                "/0/token/ItemType/TBL.F_GroupMaster/" +
                response.data.response[0].F_GroupMaster,
              index,
              response.data.response[0].ItemId
            );
          } else {
            Fn_FillListData(
              obj,
              "ItemNames2",
              API_WEB_URLS.MASTER +
                "/0/token/ItemType/TBL.F_GroupMaster/" +
                response.data.response[0].F_GroupMaster,
              index,
              response.data.response[0].ItemId
            );
          }

          obj.setState({
            [gridName]: response.data.response,
            // rows : [Object.keys(response.data.response[0])],
            isProgress: false,
          });
        }
        if (gridName == "productData") {
          obj.setState({
            [gridName]: response.data.response,
            // rows : [Object.keys(response.data.response[0])],
            isProgress: false,
          });
          console.log(response.data.response);
        } else {
          console.log("gridname", gridName);
          obj.setState({
            [gridName]: response.data.response,
            isProgress: false,
          });
        }
      } else {
        obj.setState({
          [gridName]: [],
          isProgress: false,
        });
        showToastWithCloseButton("warning", "Data can not be converted");
      }
    },
  };
  obj.props.callAdd_Data_Multipart(request);
};
export const Fn_AddEditData = (
  obj,
  data,
  apiURL,
  pushFormName,
  isMultiPart = false,
  getid
) => {
  const { arguList } = data;
  const request = {
    arguList: arguList,
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200) {
        if (apiURL == "UnitConversion/0/token") {
          obj.setState({
            UnitConvert: response.data.response.result,
          });
          console.log(response.data.response.result);
        }
        obj.setState({
          [getid]: response.data.data.id,
        });

        console.log(response.data.data.id);

        if (arguList.id === 0)
          showToastWithCloseButton("success", "Data added successfully");
        else showToastWithCloseButton("success", "Data updated successfully");
        obj.props.history.push(pushFormName);
      } else {
        if (arguList.id === 0)
          showToastWithCloseButton(
            "error",
            "Some error occurred while updating data"
          );
        else
          showToastWithCloseButton(
            "error",
            "Some error occurred while updating data"
          );
      }
    },
  };

  if (arguList.id === 0)
    if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
    else obj.props.callAdd_Data(request);
  else if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
  else obj.props.callEdit_Data(request);
};

export const Fn_AddEditDataH = (
  obj,
  data,
  apiURL,
  pushFormName,
  isMultiPart = false,
  getid,
  data1,
  data2,
  data3,
  data4,
  api1,
  api2,
  formname,
  fillName
) => {
  const { arguList } = data;
  const request = {
    arguList: arguList,
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200) {
        obj.setState({
          [getid]: response.data.data.id,
          InvoiceId: response.data.data.id,
          success_msg: true,
          save: true,
          einvoice: false,
          InvoiceSel: false,
          cancel: true,
          add: false,
          print: false,
        });
        Fn_FillListDataInvoice(obj, fillName);

        let vformData2 = new FormData();
        vformData2.append("F_InvoiceMasterH", response.data.data.id);
        vformData2.append("Data", data2);

       if(api1 !="#" ){
        Fn_AddEditData(
          obj,
          { arguList: { id: 0, formData: vformData2 } },
          api1,
          formname,
          true
        );
       }

        let vformData = new FormData();
        vformData.append("F_InvoiceMasterH", response.data.data.id);
        vformData.append("Data", data1);

        Fn_AddEditData(
          obj,
          { arguList: { id: 0, formData: vformData } },
          api2,
          formname,
          true
        );

        let vformData4 = new FormData();
        vformData4.append("F_InvoiceMasterH", response.data.data.id);
        vformData4.append("Data", JSON.stringify(data4));

   


        if (arguList.id === 0)
          showToastWithCloseButton("success", "Data added successfully");
        else showToastWithCloseButton("success", "Data updated successfully");
        obj.props.history.push(pushFormName);
      } else {
        if (arguList.id === 0)
          showToastWithCloseButton("error", "Same Invoice No. used!");
        else if (arguList.id == -1)
          showToastWithCloseButton("error", "Same Invoice No. used!");
        else showToastWithCloseButton("error", "Same Invoice No. used");
      }
    },
  };

  if (arguList.id === 0)
    if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
    else obj.props.callAdd_Data(request);
  else if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
  else obj.props.callEdit_Data(request);
};

export const Fn_ChangePassword = (
  obj,
  data,
  apiURL,
  pushFormName,
  isMultiPart = false
) => {
  const { arguList } = data;
  const request = {
    arguList: arguList,
    apiURL: apiURL,
    callback: response => {
      if (response.data.response[0].Id > 0) {
        if (arguList.id === 0)
          showToastWithCloseButton("success", "Password Changed Successfully");
        else showToastWithCloseButton("success", "Data updated successfully");
        obj.props.history.push(pushFormName);
      } else {
        if (arguList.id === 0)
          showToastWithCloseButton("error", "Old Password is wrong.");
        else
          showToastWithCloseButton(
            "error",
            "Some error occurred while updating data"
          );
      }
    },
  };

  if (arguList.id === 0)
    if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
    else obj.props.callAdd_Data(request);
  else if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
  else obj.props.callEdit_Data(request);
};

export const Fn_UserLogin = (
  obj,
  data,
  apiURL,
  pushFormName,
  isMultiPart = false
) => {
  const { arguList } = data;
  const request = {
    arguList: arguList,
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200) {
        if (arguList.id === 0)
          showToastWithCloseButton("success", "Logged in successfully");
        else showToastWithCloseButton("success", "Logged in successfully");
        obj.props.history.push(pushFormName);
      } else {
        if (arguList.id === 0)
          showToastWithCloseButton("error", "UserName or Password Incorrect!");
        else
          showToastWithCloseButton("error", "UserName or Password Incorrect!");
      }
    },
  };

  if (arguList.id === 0)
    if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
    else obj.props.callAdd_Data(request);
  else if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
  else obj.props.callEdit_Data(request);
};

export const Fn_DeleteData = (obj, id, apiURL, apiURL_Display) => {
  const request = {
    id: id,
    apiURL: apiURL,
    callback: response => {
      //console.log(response.status);
      if (response && response.status === 200) {
        obj.setState({
          confirm_alert: false,
          success_dlg: true,
          dynamic_title: "Deleted",
          dynamic_description: "Selected data has been deleted.",
        });
        showToastWithCloseButton("success", "Data deleted successfully");
        if (apiURL_Display) Fn_FillListData(obj, "gridData", apiURL_Display);
        Fn_FillListData(obj, "Invoice", apiURL_Display);
        obj.add();
      } else {
        obj.setState({
          confirm_alert: false,
          dynamic_title: "Deleted",
          dynamic_description: "Selected data has been deleted.",
        });
        showToastWithCloseButton(
          "error",
          "Some error occurred while deleting data"
        );
      }
    },
  };
  obj.props.callDelete_Data(request);
};

export const togglemodal = (obj, formData) => {
  obj.setState(prevState => ({
    modal: !prevState.modal,
    selectedFormData: formData ? formData : {},
  }));
};

export const toggleDeleteConfirm = (obj, formData, value) => {
  obj.setState({
    confirm_alert: value,
    selectedFormData: formData ? formData : {},
  });
};

export const toggleDeleteSuccess = (obj, value) => {
  obj.setState({ success_dlg: value });
};

function showToastWithCloseButton(toastType, message) {
  toastr.options = {
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    progressBar: true,
    timeOut: 2000,
  };
  if (toastType == "success") toastr.success(message);
  else if (toastType == "error") toastr.error(message);
  else if (toastType == "warning") toastr.error(message);
}
/***/
