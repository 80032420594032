import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
} from "../../store/functions";

class pageList_Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      SearchKeyArray:[],
      currentPage : 1,
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Group";
    this.modalTitle = "Group Details";
    this.rtPage_Add = "AddGroup";
    this.rtPage_Edit = "EditGroup";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Group";

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchKey = this.handleSearchKey.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  handleSearchChange(event) {
    const searchValue = event.target.value;

    // Convert the search value to lowercase for case-insensitive comparison
    const searchLowerValue = searchValue.toLowerCase();

    // Filter the data based on the search value
    const filteredData = this.state.gridData.filter(item => {
        const searchKeyValue = item[this.state.searchKey];
        return searchLowerValue == '' || searchKeyValue
    });
    
    // Update the state with the filtered data
    this.setState({
        filteredData,
        currentPage : 1
    });
}
handleSearchChange(event) {
    const searchValue = event.target.value;

    // Filter the data based on the search value
    const filteredData = this.state.gridData.filter(item => {
        // Convert the search value to lowercase for case-insensitive comparison
        const searchLowerValue = searchValue.toLowerCase();

        // Check if the search value is empty
        if (searchLowerValue === '') {
            return true; // Return true to include all items when search value is empty
        }

        // Perform string comparison for string values
        if (typeof item[this.state.searchKey] === 'string') {
            const searchKeyValue = item[this.state.searchKey].toLowerCase();
            return searchKeyValue.startsWith(searchLowerValue);
        }

        // Perform numeric comparison for numeric values
        if (typeof item[this.state.searchKey] === 'number') {
            const searchNumericValue = parseFloat(searchValue);
            return item[this.state.searchKey] === searchNumericValue;
        }

        // For other data types, return false to exclude them from the filtered data
        return false;
    });

    // Update the state with the filtered data
    this.setState({
        filteredData,
        currentPage: 1
    });
}



  handleSearchKey(item){
    this.setState({ searchKey: item },
      ()=>{
        console.log(this.state.gridData);
      }
    );
   
  }
  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };
  componentDidMount() {
  const obj = JSON.parse(localStorage.getItem("authUser"));
    Fn_FillListData(this.obj, "gridDataSearch", this.API_URL + "/Id/0" );
  }
  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }
  btnEdit_onClick(formData) {
    this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  renderGridHeader() {
    return (
      <>
        <th>Name</th>
       
      </>
    );
  }
  renderGridBody(formData) {
    return (
      <>
        <td>{formData.Name}</td>
        
      </>
    );
  }
  renderModalBody(selectedFormData) {
    return (
      <>
        <p className="mb-4">
        
        </p>
      </>
    );
  }
  render() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return (
      <div className="page-content">
      <RCDisplayPage
        //page header paramaters
        SearchKeyArray ={this.state.SearchKeyArray}
    
        currentPage={this.state.currentPage}
        searchKey ={this.state.searchKey}
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={this.breadCrumbItem}
        obj={this.obj}
        //column paramaters
        isSearchBox={true}
        isSNo={false}
        isCheckBox={false}
        isViewDetails={false}
        //grid paramaters
        gridData={this.state.filteredData}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        handleSearchKey ={this.handleSearchKey}
        handleSearchChange ={this.handleSearchChange}
        handlePageChange={this.handlePageChange}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={true}
        isEdit={obj.UserType=='Admin' ? true :  false}
        isDelete={obj.UserType=='Admin' ? true :  false}
        isPagination={true}
      ></RCDisplayPage>
      </div>
    );
  }
}
export default compose(container)(pageList_Group);
