/* eslint-disable */

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";

import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";

import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
  Fn_ChangeStateValue,
  Fn_FillListData2,
  Fn_AddEditDataH,
  Fn_DeleteData,
  Fn_FillListDataGlobal,
  Fn_GetReport,
} from "../../store/functions";
import AddDeleteTableRows from "./AddDeleteTableRow";
import AddDeleteTableRowsTax from "./AddDeleteTableRowtax";
import { toggleRightSidebar } from "store/actions";
import AddDeleteTableRowsCharges from "./AddDeleteTableRowCharges";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { event } from "jquery";

function showToastWithCloseButton(toastType, message) {
  toastr.options = {
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    progressBar: true,
    timeOut: 2000,
  };
  if (toastType == "success") toastr.success(message);
  else if (toastType == "error") toastr.error(message);
}

export const formatDate = () => {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const sum = data => {
  let sum = 0; // initialize sum

  // Iterate through all elements
  // and add them to sum
  for (let i = 0; i < data.length; i++) sum += parseFloat(data[i].Amount);

  return sum.toFixed(2);
};

export const taxsum = data => {
  let sum = 0; // initialize sum

  // Iterate through all elements
  // and add them to sum
  for (let i = 0; i < data.length; i++) sum += parseFloat(data[i].amount);

  return sum.toFixed(2);
};

class pageAddEdit_EstimateInvoice extends Component {
  constructor(props) {
    super(props);
    var today = formatDate();
    this.state = {
      id: 0,
      formData: {},
      success_msg: false,
      F_MISScheme: 0,
      InvoiceData: [{}],
      IsDiff: false,
      IsCon: true,
      visible: false,
      edit: true,
      OtherAddress: "",
      Add1: false,
      Add2: false,
      Add3: false,
      save: false,
      cancel: false,
      DiscountType : 'Percent',
      add: true,
      einvoice: true,
      InvoiceSel: false,
      print: true,
      dd: false,
      Rate : "Rate1",
      ItemName : [],
      GroupMaster:[],
      UnitMaster :[],
      InvoiceDataL: [
        {
          IsDirectInvoice: true,
          CodeNo: "",
          ItemName: "",
          Qty : "",
          F_GroupMaster : "",
          F_UnitMaster : -1,
          Rate:'',
          Disc : 0,
          Amount :"",
          TaxName : ""
        },
      ],
      outline: "",
      Tax: [],
      Charges: [],
      TaxData: [
        {
          IndexRow: "",
          CGST: "",
          IGST: "",
          Amount: "",
        },
      ],
      InvoiceId: "",
      VoucherNo: "",
      VoucherDate: today,
      EntryMode : '',
      StopOn :'',
     
      PartyDetail : '',
      Info :'',
      GSTTotalTaxAmount: [
        {
          tax: "",
          amount: "",
          igst: "",
        },
      ],

      VoucherGlobalOptions: [
        {
          IsVoucherNoEnable: false,
          VoucherNo: "",
          BackgroundColor: "",
          TextColor: "",
          IsActualSoot: false,
        },
      ],
      BoldToken: [
        {
          Token: "",
        },
      ],
      CustomerId: API_WEB_URLS.Customer,
      canceleinvoice: false,
      ReasonRemarks: "",
      CancelReasonId: -1,
      cancelinvoiceName: "Cancel Invoice",
      canceleinvoiceBtn: false,
      editbtnname: "EDIT",
      oldvouhcerdate: false,
      deletedlg: false,
      IsCRN: false,
      IsDBN: false,
      BattaRate: [
        {
          Rate: 0,
        },
      ],
      IsEway: false,

      // backgroundColor : 'black',
      // textcolor : '#ddb84a'
      // backgroundColor : '#E2D1F9',
      // textcolor : '#317773'
    };

    this.obj = this;
    this.formTitle = "Invoice";
    this.breadCrumbTitle = "Invoice";
    this.breadCrumbItem = "Add " + this.formTitle;

    this.API_URL_SAVE = API_WEB_URLS.ESTIMATEL + "/0/token";
    this.API_URL_SAVEH = API_WEB_URLS.ESTIMATEH + "/0/token";
    this.API_URL_SAVETAX = API_WEB_URLS.ESTIMATETAX + "/0/token";
    this.API_URL_TAXABLEAMOUNT = API_WEB_URLS.INVOICETAXABLE + "/0/token";
    this.API_URL_SAVECHARGE = API_WEB_URLS.INVOICECHARGE + "/0/token";
    this.pushFormName = "/estimateinvoice";
    this.rtPage_Print = "/membersynopsisprint";

    this.API_URL = API_WEB_URLS.MASTER + "/0/token/InvoiceMasterTax";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno = this.syno.bind(this);
    this.addrow = this.addrow.bind(this);
    this.editrow = this.editrow.bind(this);
    this.add = this.add.bind(this);
    this.cancel = this.cancel.bind(this);
    this.edit = this.edit.bind(this);
    this.addchargesrow = this.addchargesrow.bind(this);
    this.einvoice = this.einvoice.bind(this);
    this.handler2 = this.handler2.bind(this);
    this.valueset = this.valueset.bind(this);
    this.deleterow = this.deleterow.bind(this);
    this.delete = this.delete.bind(this);
    this.tog_fullscreen = this.tog_fullscreen.bind(this);
    this.validateCalculations = this.validateCalculations.bind(this);
    this.canceleinvoice = this.canceleinvoice.bind(this);
    this.proceedeinvoice = this.proceedeinvoice.bind(this);
    this.changeINV = this.changeINV.bind(this);
    this.copy = this.copy.bind(this);
    this.copyEstimate = this.copyEstimate.bind(this);
    this.replaceSubArray = this.replaceSubArray.bind(this);
    this.addSubArray = this.addSubArray.bind(this);

  
    this.paste = this.paste.bind(this);
    this.handleRateChange = this.handleRateChange.bind(this);

    //ref

    this.LedgerId = React.createRef();
    this.invoice = React.createRef();
    this.Sinvoice = React.createRef();
    this.vno = React.createRef();
    this.vdate = React.createRef();
    this.chno = React.createRef();
    this.chdate = React.createRef();
    this.pono = React.createRef();
    this.podate = React.createRef();
    this.grrno = React.createRef();
    this.grrdate = React.createRef();
    this.ledger = React.createRef();
    this.salesledger = React.createRef();
    this.transport = React.createRef();
    this.vhno = React.createRef();
    this.AddInfo = React.createRef();
    this.PartyDetail = React.createRef();
    this.diffcon = React.createRef();
    this.IsAdd1 = React.createRef();
    this.IsAdd2 = React.createRef();
    this.IsAdd3 = React.createRef();

    this.AddBtn = React.createRef();
    this.TranId = React.createRef();
    this.tranmode = React.createRef();
    this.trandist = React.createRef();

    this.Code = this.state.InvoiceDataL.map(() => React.createRef([]));
  }

  syno() {
    this.setState({
      success_msg: false,
    });
  }

  addchargesrow() {
    var i = 0;
    var array = this.state.InvoiceDataL;
    var largest = 0;

    for (i = 0; i < array.length; i++) {
      if (Number(array[i].IGST) > Number(largest)) {
        largest = array[i].IGST;
      }
    }

    this.setState({
      Charges: [
        ...this.state.Charges,
        {
          charges: "",
          amount: "",
          IGST: largest,
        },
      ],
    });
  }

  copy() {
    localStorage.setItem("copydata2", JSON.stringify(this.state.InvoiceDataL));
    alert("Copied Successfully!");
  }
  copyEstimate() {
    localStorage.setItem("copydata3", JSON.stringify(this.state.InvoiceDataL));
    alert("Copied Successfully!");
  }
  
  paste() {
    const storedData = localStorage.getItem("copydata2");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      // Assuming you want to set the retrieved data to InvoiceDataL
      this.setState({ InvoiceDataL: parsedData },()=>{
        this.setState(prevState => {
          let totalQty = prevState.InvoiceDataL.reduce((accumulator, currentItem) => {
              console.log(currentItem);
              return accumulator + Number(currentItem.Qty);
          }, 0); 
  
          return {
              TotalQuantity: totalQty
          };
      });
        this.gst();
      });
      Fn_FillItemName(this.obj,parsedData)
    } else {
      alert("No data to paste!");
    }
  }
  handleRateChange(e){
   
    this.setState({
      RateType : e.target.value
    })
  }
  deleterow(index) {
    if(this.state.InvoiceDataL.length===1  ){
      
      return
    }
    console.log(index);
    const rows = [...this.state.InvoiceDataL];
    rows.splice(index, 1);
    this.setState({ InvoiceDataL: rows }, () => {
      this.gst();
        this.setState(prevState => {
            let totalQty = prevState.InvoiceDataL.reduce((accumulator, currentItem) => {
                console.log(currentItem);
                return accumulator + Number(currentItem.Qty);
            }, 0); 

            return {
                TotalQuantity: totalQty
            };
        });
    });
}


  addrow() {
    if (this.state.InvoiceDataL[0].Qty > 0) {
      let uu = {
        IsDirectInvoice: true,
        CodeNo: "",
        ItemName: "",
        Qty:'',
        Rate: "",
        F_GroupMaster : "",
        Disc: 0,
        F_UnitMaster : -1,
        Amount: "",
        TaxName: "",
      };
      this.setState({ InvoiceDataL: [...this.state.InvoiceDataL, uu] });
    } else {
      alert("Can't add row!");
    }
  }

  cancel() {
    
    this.setState({
      add: false,
      edit: true,
      cancel: true,
      save: true,
      einvoice: true,
      InvoiceSel: false,
      print: true,
    });

    var today = formatDate();
    this.setState({
      InvoiceDataL: [
        {
          IsDirectInvoice: true,
        CodeNo: "",
        ItemName: "",
        Qty:'',
        Rate: "",
        F_GroupMaster : "",
        Disc: 0,
        F_UnitMaster : -1,
        Amount: "",
        TaxName: "",
        },
      ],
      VoucherDate: today,
      ChallanNo: "",
      ChallanDate: today,
      PONo: "",
      PODate: "",
      GRRNo: "",
      GRRDate: "",
      F_LedgerMaster: "",
      SalesLedgerId: "",
      Transport: "",
      VehicleNo: "",
      Through: "",
      Signatory: "",
      LedgerId: "",
      OtherAddress: "",
      Add3: false,
      Add2: false,
      Add1: false,
      IsDiff: false,
      RoundedValue: 0,
      GrossAmount: 0,
      NetAmount: 0,
      TaxAmount: 0,
    });

    this.setState({
      formData: {
        InvoiceId: "",
        VoucherNo: "",
        VoucherDate: "",
        ChallanNo: "",
        ChallanDate: today,
        PONo: "",
        PODate: today,
        GRRNo: "",
        GRRDate: today,
        LedgerId: "",
        SalesLedgerId: "",
        Transport: "",
        VehicleNo: "",
        Through: "",
        Signatory: "",
      },

      NetAmount: "",
      RoundedValue: "",
      TaxAmount: "",
      GrossAmount: "",
      InvoiceData: [
        {
          InvoiceId: "",
          VoucherNo: "",
          VoucherDate: "",
          EntryMode: "",
          DiscountType: 'Percent',
          Rate: "",
          PartyDetail: today,
          Info: "",
       
          F_LedgerMaster: "",
        },
      ],
    });

    var iu = [];

    const rowsInput = [...iu];
    this.setState({ Tax: rowsInput, Charges: rowsInput });

    Fn_FillListData(
      this.obj,
      "Invoice",
      API_WEB_URLS.MASTER + "/0/token/Estimate/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "ChargesLedger",
      API_WEB_URLS.MASTER + "/0/token/ChargesLedger/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "Ledger",
      API_WEB_URLS.MASTER + "/0/token/LedgerForInvoice/Id/0"
    );
  }

  edit() {
    this.setState({
      save: false,
      add: true,
      edit: true,
      cancel: false,
      InvoiceSel: true,
      einvoice: true,
      print: true,
    });
  }

  add() {
   

    var today = formatDate();
    this.setState({
      save: false,
      cancel: false,
      add: true,
      InvoiceSel: true,
      edit: true,
      canceleinvoiceBtn: true,
      delete: true,
      print: true,
      einvoice: true,
    });

    var today = formatDate();
    this.setState({
      InvoiceDataL: [
        {
          IsDirectInvoice: true,
        CodeNo: "",
        ItemName: "",
        Qty:'',
        Rate: "",
        F_GroupMaster : "",
        Disc: 0,
        F_UnitMaster : -1,
        Amount: "",
        TaxName: "",
        },
      ],
      VoucherDate: today,
      ChallanNo: "",
      ChallanDate: today,
      PONo: "",
      PODate: "",
      GRRNo: "",
      GRRDate: "",
      F_LedgerMaster: "",
      SalesLedgerId: "",
      Transport: "",
      VehicleNo: "",
      Through: "",
      Signatory: "",
      LedgerId: "",
      OtherAddress: "",
      Add3: false,
      Add2: false,
      Add1: false,
      IsDiff: false,
      RoundedValue: 0,
      GrossAmount: 0,
      NetAmount: 0,
      TaxAmount: 0,
    });

    this.setState({
      formData: {
        InvoiceId: "",
        VoucherNo: "",
        VoucherDate: "",
        ChallanNo: "",
        ChallanDate: today,
        PONo: "",
        PODate: today,
        GRRNo: "",
        GRRDate: today,
        LedgerId: "",
        SalesLedgerId: "",
        Transport: "",
        VehicleNo: "",
        Through: "",
        Signatory: "",
      },

      InvoiceData: [
        {
          InvoiceId: "",
          VoucherNo: "",
          VoucherDate: "",
          EntryMode: "",
          DiscountType: 'Percent',
          Rate: "",
          PartyDetail: today,
          Info: "",
       
          F_LedgerMaster: "",
        },
      ],
    });

    var iu = [];

    const rowsInput = [...iu];
    this.setState({ Tax: rowsInput, Charges: rowsInput });
  }

  getAddress = event => {
    if (event.target.name == "Add1") {
      this.setState({
        Add1: !this.state.Add1,
        OtherAddress: this.state.ConsigneeAddress[0].Address1,
        Add2: false,
        Add3: false,
      });
      if (this.state.Add1 == true) {
        this.setState({ OtherAddress: "" });
      }
    } else if (event.target.name == "Add2") {
      this.setState({
        Add1: false,
        OtherAddress: this.state.ConsigneeAddress[0].Address2,
        Add2: !this.state.Add2,
        Add3: false,
      });
      if (this.state.Add2 == true) {
        this.setState({ OtherAddress: "" });
      }
    } else if (event.target.name == "Add3") {
      this.setState({
        Add1: false,
        OtherAddress: this.state.ConsigneeAddress[0].Address3,
        Add2: false,
        Add3: !this.state.Add3,
      });
      if (this.state.Add3 == true) {
        this.setState({ OtherAddress: "" });
      }
    }
  };

  canceleinvoice() {
    if (this.state.ReasonRemarks == "") {
      alert("Please fill ReasonRemarks");
    } else {
      let vformData4 = new FormData();

      vformData4.append("F_InvoiceMasterH", this.state.InvoiceId);
      vformData4.append("CnlRsn", this.state.CancelReasonId);
      vformData4.append("CnlRem", this.state.ReasonRemarks);

      const requestOptions = {
        method: "POST",
        body: vformData4,
      };
      var url = API_WEB_URLS.BASE + "EInvoiceCancel/0/token";
      fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.data.data.id == 1 || data.data.data.id == "1") {
            showToastWithCloseButton(
              "success",
              "E-Invoice Cancelled Successfully!"
            );
          } else {
            showToastWithCloseButton("success", data.data.data.name);
          }
        })
        .catch(error => {
          showToastWithCloseButton("error", "Somthing went wrong");
        });

      this.setState({ canceleinvoice: false });
    }
  }

  OnConChange = event => {
    var IsDiff = event.target.checked;

    if (event.target.checked == true) {
      this.setState({ IsCon: false, IsDiff: true });
    } else {
      this.setState({ IsCon: true, IsDiff: false });
    }
  };

  gst() {
    console.log("coming here")
    var arr = [];
    var CGSTName = "";
    var SGSTName = "";
    var IGSTName = "";

    for (let i = 0; i < this.state.InvoiceDataL.length; i++) {
      const lk = arr.findIndex(
        product =>
          Number(product.igst) == Number(this.state.InvoiceDataL[i].IGST)
      );

      if (lk == -1) {
        arr = [
          ...arr,
          {
            tax: CGSTName,
            amount: parseFloat(this.state.InvoiceDataL[i].Amount),
            igst: this.state.InvoiceDataL[i].IGST,
          },
        ];
        console.log(arr);
      } else {
        var LastAmount = 0;

        const rowsInput2 = [...arr];

        LastAmount =
          parseFloat(rowsInput2[lk]["amount"]) +
          parseFloat(this.state.InvoiceDataL[i].Amount);

        rowsInput2[lk]["amount"] = LastAmount;
      }
    }

    //largest

    var array = this.state.InvoiceDataL;
    var largest = 0;

    for (let i = 0; i < array.length; i++) {
      if (Number(array[i].IGST) > Number(largest)) {
        largest = array[i].IGST;
      }
    }

    for (let i = 0; i < this.state.Charges.length; i++) {
      const lk = arr.findIndex(product => product.igst == largest);

      if (lk == -1) {
        //do nothing
      } else {
        var LastAmount = 0;

        const rowsInput2 = [...arr];

        LastAmount =
          parseFloat(rowsInput2[lk]["amount"]) +
          parseFloat(this.state.Charges[i].amount);

        rowsInput2[lk]["amount"] = LastAmount;
      }
    }

    var iu = [];

    for (let i = 0; i < arr.length; i++) {
      var su = parseFloat(arr[i].igst) / 2;

      if (su > 0) {
        CGSTName = "CGST " + parseFloat(arr[i].igst) / 2 + " %";
        SGSTName = "SGST " + parseFloat(arr[i].igst) / 2 + " %";

        // let IGST = this.state.Ledger.findIndex(
        //   pro => pro.Id == this.state.LedgerId
        // );
        // IGST = this.state.Ledger[IGST].OwnState;
        let IGST = 2;
        if (IGST == 1) {
          IGSTName = "IGST " + parseFloat(arr[i].igst) + " %";

          iu = [
            ...iu,
            {
              tax: IGSTName,
              amount: (
                (parseFloat(arr[i].amount) * parseFloat(arr[i].igst)) /
                100
              ).toFixed(2),
              igst: arr[i].igst,
            },
          ];
        } else {
          iu = [
            ...iu,
            {
              tax: CGSTName,
              amount: (
                (parseFloat(arr[i].amount) * (parseFloat(arr[i].igst) / 2)) /
                100
              ).toFixed(2),
              igst: arr[i].igst,
            },
            {
              tax: SGSTName,
              amount: (
                (parseFloat(arr[i].amount) * (parseFloat(arr[i].igst) / 2)) /
                100
              ).toFixed(2),
              igst: arr[i].igst,
            },
          ];
        }
      }
    }

    const rowsInput = [...iu];

    const gsttax = [...arr];

    var ch = parseFloat(taxsum(this.state.Charges));
    var char = 0;
    if (ch != NaN && ch > 0) {
      char = ch;
    }

    var NetAmountBeforeRound =
      parseFloat(sum(this.state.InvoiceDataL)) +
      parseFloat(taxsum(iu)) +
      parseFloat(char);
    var NetAmountAterRound = Math.round(NetAmountBeforeRound);
    var RoundedValue = NetAmountAterRound - NetAmountBeforeRound;

    this.setState({
      Tax: rowsInput,
      GrossAmount:
        parseFloat(sum(this.state.InvoiceDataL)) +
        parseFloat(taxsum(this.state.Charges)),
      TaxAmount: taxsum(iu),
      NetAmount: NetAmountAterRound,
      RoundedValue: RoundedValue.toFixed(2),
      GSTTotalTaxAmount: gsttax,
    });
    // iu.forEach(val => this.state.Tax.push(Object.assign({}, val)));
  }

  editChargesrow = (index, evnt,data) => {
    const { name, value } = evnt.target;

    const rowsInput = [...this.state.Charges];
    rowsInput[index][name] = value;

    this.setState({ Charges: rowsInput });
    if (name == "amount") {
      this.gst();
    }
  };
  replaceSubArray(mainArray, index, newSubArray, Id) {
    if (index >= 0 && index < mainArray.length) {
      mainArray[index] = newSubArray;
      this.setState({
        ItemName: mainArray,
      },()=>{
        if (Id != null) {
          const rowsInput = [...this.state.InvoiceDataL];
          rowsInput[index]["ItemName"] = Id;
          this.setState({ InvoiceDataL: rowsInput },()=>{
          
            let item = this.state.ItemName[index].find(item => item.Id == Id);
      
            const { TaxName, F_UnitMaster } = item;
           
         
            rowsInput[index]["F_UnitMaster"] = F_UnitMaster;
            this.setState({
              F_UnitMaster,
            });
            rowsInput[index]["TaxName"] = TaxName;
            console.log(TaxName);
            this.setState({ InvoiceDataL: rowsInput });
            if (this.state.Rate) {
              let rate = this.state.Rate;
            
              rowsInput[index]["Rate"] = item[rate];
              this.setState({ RateForSend: item[rate] });
              this.setState({ InvoiceDataL: rowsInput });
            }
              });
        }
      });
    } else {
      console.log("Index out of bounds");
    }
    
  }
  addSubArray(mainArray, newSubArray,index, Id) {
    if (!Array.isArray(mainArray)) {
      console.error("mainArray is not an array");
      return;
    }
    console.log("in addSubArray", newSubArray, mainArray);
    mainArray.push(newSubArray);
    this.setState({
      ItemName: mainArray,
    },()=>{
      if (Id != null) {
        const rowsInput = [...this.state.InvoiceDataL];
        rowsInput[index]["ItemName"] = Id;
        this.setState({ InvoiceDataL: rowsInput },()=>{
          
      let item = this.state.ItemName[index].find(item => item.Id == Id);

      const { TaxName, F_UnitMaster } = item;
     
   
      rowsInput[index]["F_UnitMaster"] = F_UnitMaster;
      this.setState({
        F_UnitMaster,
      });
      rowsInput[index]["TaxName"] = TaxName;
      console.log(TaxName);
      this.setState({ InvoiceDataL: rowsInput });
      if (this.state.Rate) {
        let rate = this.state.Rate;
      
        rowsInput[index]["Rate"] = item[rate];
        this.setState({ RateForSend: item[rate] });
        this.setState({ InvoiceDataL: rowsInput });
      }
        });
      }
    });
   
  }
  editrow = (index, evnt) => {
    const { name, value } = evnt.target;

    const rowsInput = [...this.state.InvoiceDataL];
    if (name === "F_GroupMaster") {
      if (value == -1) {
        rowsInput[index]["F_GroupMaster"] = "";
        rowsInput[index]["F_UnitMaster"] = -1;
        rowsInput[index]["ItemName"] = "";
        return;
      }
      var groupVal = rowsInput[index]["F_GroupMaster"];
      console.log(groupVal, "groupval");
      if (groupVal != null && groupVal != "" && groupVal != undefined) {
        console.log("in group val ");
        Fn_FillListData(
          this.obj,
          "ItemName1",
          API_WEB_URLS.MASTER + "/0/token/ItemType/TBL.F_GroupMaster/" + value,
          index
        );
      } else {
        Fn_FillListData(
          this.obj,
          "ItemName2",
          API_WEB_URLS.MASTER + "/0/token/ItemType/TBL.F_GroupMaster/" + value
        );
      }
    }
   
    rowsInput[index][name] = value;

    this.setState({ InvoiceDataL: rowsInput });

    var MainRate = 0;
    
    if(name=='ItemName' && value == '-1'){
      rowsInput[index][name] = "";
      this.setState({ InvoiceDataL: rowsInput });
      return
    }
    if(name=== 'F_UnitMaster'){
      
      if(value == -1){
        rowsInput[index]['F_UnitMaster'] = -1;
        this.setState({ InvoiceDataL: rowsInput });
        return
      }
      let UnitTo
     
      let item = this.state.UnitMaster.find((item)=>item.Id == value)
        const {Name} = item
        UnitTo = Name
        let vformData1 = new FormData();
        vformData1.append("F_ItemMaster", this.state.F_ItemMaster);
        vformData1.append("UnitTo", UnitTo);
        vformData1.append("Rate", this.state.RateForSend);

        Fn_GetReport(
          this.obj,
          { arguList: { id: 0, formData: vformData1 } },
          "UnitConversion/0/token",
          "kk",
          index,
          
        );
      
    }
    if(name=='CodeNo'){
      let vformData1 = new FormData();
      
      
        vformData1.append("Code",value);

        Fn_GetReport(
          this.obj,
          { arguList: { id: 0, formData: vformData1 } },
          "SearchByCode/0/token",
          "CodeArr",
          index,
          true
          
        );
    }
    if(name==='ItemName'){
      this.setState({
        F_ItemMaster: value,
      })
      const index1 = evnt.target.selectedIndex;
      const el = evnt.target.childNodes[index1];
      const option = el.getAttribute("id");
      rowsInput[index]["IGST"] = option;

      let item = this.state.ItemName[index].find((item)=>item.Id == value)
      
      const { TaxName,F_UnitMaster,Alias} = item;
      rowsInput[index]["CodeNo"] = Alias;
      rowsInput[index]["F_UnitMaster"] = F_UnitMaster;
      this.setState({
        F_UnitMaster
      })
      rowsInput[index]["TaxName"] = TaxName;
      console.log(TaxName)
      this.setState({ InvoiceDataL: rowsInput });
      
      if(this.state.Rate){
        let rate = this.state.Rate
        console.log(this.state.Rate)
        console.log(rate)
        console.log('rate' , item[rate])
        rowsInput[index]['Rate'] = item[rate];
        this.setState({RateForSend : item[rate]})
        this.setState({ InvoiceDataL: rowsInput });
      }
    } if (rowsInput[index]["Qty"] != "" ) {
      var Rate = rowsInput[index]["Rate"];

      var Qty = rowsInput[index]["Qty"];

      var Amount = parseFloat(Rate) * parseFloat(Qty);

      Amount = parseFloat(Amount).toFixed(2);

      rowsInput[index]["Amount"] = Amount;
      this.setState({ InvoiceDataL: rowsInput });
    }
    if(name=='Qty'){
    
    let totalQty = this.state.InvoiceDataL.reduce((accumulator, currentItem) => {
      console.log(currentItem)
      return Number(accumulator) + Number(currentItem.Qty);
      
  }, 0); 
  console.log(totalQty)
  this.setState({
    TotalQuantity : totalQty
  })
    }
    if (name === 'Disc') {
      let dis = this.state.DiscountType ? this.state.DiscountType : 0;
      const rowsInput = [...this.state.InvoiceDataL];
      var Rate = rowsInput[index]["Rate"];
      var Qty = rowsInput[index]["Qty"];
      var Amount = parseFloat(Rate) * parseFloat(Qty);
    
      if (dis === 'Flat') {
        // Subtract flat discount if the value is provided
        var discountAmount = parseFloat(evnt.target.value || 0); 
        Amount -= discountAmount;
      } else if (dis === 'Percent') {
        // Subtract percentage discount if the value is provided
        var discountPercentage = parseFloat(evnt.target.value || 0); 
        var discountAmount = (Amount * discountPercentage) / 100;
        Amount -= discountAmount;
      }
    
      Amount = parseFloat(Amount).toFixed(2);
      rowsInput[index]["Amount"] = Amount;
      this.setState({ InvoiceDataL: rowsInput });
    }
    
    
    this.gst();
  };

  validateCalculations(invoiceData) {
    for (let i = 0; i < invoiceData.length; i++) {
      const record = invoiceData[i];
      const qty = parseFloat(record.Qty);
      const rate = parseFloat(record.Rate);
      const amount = parseFloat(record.Amount);
      const discount = parseFloat(record.Disc) || 0; // Assuming discount is provided in the record
      const discountType = this.state.DiscountType || 'None'; // Assuming DiscountType is available in the state
  
      let calculatedAmount = qty * rate;
  
      // Apply discount based on the type
      if (discountType === 'Flat') {
        calculatedAmount -= discount;
      } else if (discountType === 'Percent') {
        const discountAmount = (calculatedAmount * discount) / 100;
        calculatedAmount -= discountAmount;
      }
  
      calculatedAmount = calculatedAmount.toFixed(2);
  
      if (calculatedAmount != amount.toFixed(2)) {
        console.log(`Calculated Amount: ${calculatedAmount}`);
        console.log(`Qty: ${qty}`);
        console.log(`Rate: ${rate}`);
        console.log(`Amount: ${amount}`);
        console.log(`Discount: ${discount}`);
        alert(`Error: Amount mismatch for record ${i + 1}`);
        return 2;
      }
    }
  
    alert("Calculation validation successful");
    return 1;
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }
  handleKeyDown = event => {
    const tagName = event.target.tagName.toLowerCase();
  
    
    const ignoreTags = ['input', 'textarea', 'select','avfield'];
  
    if (ignoreTags.includes(tagName)) {
      return; 
    }
    
    switch (event.key) {
      case "F1":
        event.preventDefault();
        this.props.history.push("/dashboard");
        break;
        case "P":
          case "p":  
            event.preventDefault();
            
           if(this.state.InvoiceId>0){
            this.tog_fullscreen();
           }else{
            alert('please select Voucher')
           }
            break;
        case "E":
          case "e":  
            event.preventDefault();
            
           if(this.state.InvoiceId>0){
            this.edit();
           }else{
            alert('please select Voucher')
           }
            break;
        case "A":
          case "a":  
            event.preventDefault();
            
           if(!this.state.add){
            this.add();
            this.vno.current.focus()
           }else{
            alert('please select Voucher')
           }
            break;
        case "I":
          case "i":  
            event.preventDefault();
            
           if(!this.state.add || !this.state.cancel){
            this.Sinvoice.current.focus()
           }else{
            alert('can not add')
           }
            break;
        case "C":
          case "c":  
            event.preventDefault();
            
           if(!this.state.cancel){
            this.cancel();
           }else{
            alert('can not cancel')
           }
            break;
        case "Delete":
          case "delete":  
            event.preventDefault();
            
           if(this.state.InvoiceId>0){
            this.delete();
           }else{
            alert('please select Voucher')
           }
            break;
            case "X":
              case "x":  
                event.preventDefault();
                this.copyEstimate();
                break;
      default:
        break;
    }
  };
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    //navbar-header

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });

    //Filling DropDowns
   
    
    Fn_FillListData(
      this.obj,
      "GroupMaster",
      API_WEB_URLS.MASTER + "/0/token/Group/Id/0"
    );
    Fn_FillListData
    (
      this.obj,
      "UnitMaster",
      API_WEB_URLS.MASTER + "/0/token/Unit/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "Invoice",
      API_WEB_URLS.MASTER + "/0/token/Estimate/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "BoldToken",
      API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0"
    );
    Fn_FillListDataGlobal(
      this.obj,
      "VoucherGlobalOptions",
      API_WEB_URLS.MASTER + "/0/token/VoucherGlobalOptionsEstimate/Id/0"
    );
    // Fn_FillListData(
    //   this.obj,
    //   "ChargesLedger",
    //   API_WEB_URLS.MASTER + "/0/token/ChargesLedger/Id/0"
    // );
    // Fn_FillListData(
    //   this.obj,
    //   "SalesLedger",
    //   API_WEB_URLS.MASTER + "/0/token/SalesLedger/Id/0"
    // );
    Fn_FillListData(
      this.obj,
      "Ledger",
      API_WEB_URLS.MASTER + "/0/token/LedgerForInvoice/Id/0"
    );
   

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }

  componentDidUpdate() {
    if (
      this.state.VoucherGlobalOptions[0].BackgroundColor != "" &&
      this.state.VoucherGlobalOptions[0].BackgroundColor != undefined &&
      this.state.VoucherGlobalOptions[0].BackgroundColor != null
    ) {
      const formLabels = document.querySelectorAll(".col-form-label");

      // Loop through the selected elements and change their color
      formLabels.forEach(label => {
        label.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const formcontrol = document.querySelectorAll(".form-control");

      // Loop through the selected elements and change their color
      formcontrol.forEach(label => {
        label.style.backgroundColor =
          this.state.VoucherGlobalOptions[0].BackgroundColor;
        label.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const formselect = document.querySelectorAll(".form-select");
      // Loop through the selected elements and change their color
      formselect.forEach(label => {
        label.style.backgroundColor =
          this.state.VoucherGlobalOptions[0].BackgroundColor;
        label.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const header = document.querySelectorAll(".navbar-header");
      // Loop through the selected elements and change their color
      header.forEach(label => {
        label.style.backgroundColor =
          this.state.VoucherGlobalOptions[0].BackgroundColor;
        label.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const sidebar = document.querySelectorAll(".vertical-menu");
      // Loop through the selected elements and change their color
      sidebar.forEach(label => {
        const anchorTags = label.querySelectorAll("li a");

        anchorTags.forEach(anchor => {
          anchor.style.color = this.state.VoucherGlobalOptions[0].TextColor;
        });
        label.style.backgroundColor =
          this.state.VoucherGlobalOptions[0].BackgroundColor;
        label.style.backgroundImage = "none";
      });

      const anchorTags = document.querySelectorAll("th");
      anchorTags.forEach(anchor => {
        anchor.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const button = document.querySelectorAll(".btn-danger");
      button.forEach(anchor => {
        anchor.style.backgroundColor =
          this.state.VoucherGlobalOptions[0].TextColor;
        anchor.style.color = this.state.VoucherGlobalOptions[0].BackgroundColor;
      });

      const headeritem = document.querySelectorAll(".header-item");
      headeritem.forEach(anchor => {
        anchor.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const ul = document.querySelectorAll("ul li a i");
      ul.forEach(anchor => {
        anchor.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const ul2 = document.querySelectorAll("#sidebar-menu ul li.menu-title");
      ul2.forEach(anchor => {
        anchor.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });
    }
  }

  OnLedgerChange = event => {
    this.setState({
      save: false,
      F_LedgerMaster: event.target.value,
      LedgerId: event.target.value,
    });

    // Fn_FillListData(
    //   this.obj,
    //   "ItemName",
    //   API_WEB_URLS.MASTER + "/0/token/ItemName/Id/0"
    // );
    // Fn_FillListData(
    //   this.obj,
    //   "Ply",
    //   API_WEB_URLS.MASTER +
    //     "/0/token/GetItemByPlyPartyDescription/Id/" +
    //     event.target.value
    // );

    // Fn_FillListData(
    //   this.obj,
    //   "PrintDesc",
    //   API_WEB_URLS.MASTER +
    //     "/0/token/GetPrintPartyDescription/Id/" +
    //     event.target.value
    // );

    // Fn_FillListData(
    //   this.obj,
    //   "ConsigneeAddress",
    //   API_WEB_URLS.MASTER +
    //     "/0/token/LedgerConsigneeAddress/Id/" +
    //     event.target.value
    // );

    // Fn_FillListData(
    //   this.obj,
    //   "BattaRate",
    //   API_WEB_URLS.MASTER + "/0/token/BattaRate/Id/" + event.target.value
    // );

    // Fn_FillListData(
    //   this.obj,
    //   "PlyPartyH",
    //   API_WEB_URLS.MASTER + "/0/token/PlyPartyH/Id/" + event.target.value
    // );
  };

  OnInvoiceChange = event => {
    
    this.setState({
      add: false,
      edit: false,
      cancel: true,
      save: true,
      einvoice: false,
      print: false,
      ReasonRemarks: "",
      CancelReasonId: -1,
      delete: false,
      ItemName : [],
    });

    var iu = [];

    const rowsInput = [...iu];
    this.setState({
      Tax: rowsInput,
      IsCon: true,
      Add1: false,
      Add2: false,
      Add3: false,
      IsDiff: false,
      OtherAddress: "",
    });

    Fn_FillListData2(
      this.obj,
      "InvoiceData",
      API_WEB_URLS.MASTER +
        "/0/token/GetDetailByEstimate/Id/" +
        event.target.value
    );
    Fn_FillListData(
      this.obj,
      "UnitMaster",
      API_WEB_URLS.MASTER + "/0/token/Unit/Id/0"
    );
    this.setState({
     
      InvoiceId: event.target.value,
    
    });

    this.setState({
      InvoiceDataL: [
        {
          IsDirectInvoice: true,
          CodeNo: "",
          ItemName: "",
          F_GroupMaster : "",
          Qty:'',
          Rate: "",
          F_UnitMaster : -1,
          Disc: 0,
  
          Amount: "",
          TaxName: "",
        },
      ],
    });

    // Fn_FillListData(
    //   this.obj,
    //   "ItemName",
    //   API_WEB_URLS.MASTER + "/0/token/ItemName/Id/0"
    // );

    Fn_FillListData(
      this.obj,
      "InvoiceDataL",
      API_WEB_URLS.MASTER + "/0/token/EstimateL/Id/" + event.target.value
    );
    Fn_FillListData(
      this.obj,
      "Tax",
      API_WEB_URLS.MASTER + "/0/token/EstimateMasterTax/Id/" + event.target.value
    );

    // Fn_FillListData(
    //   this.obj,
    //   "Charges",
    //   API_WEB_URLS.MASTER + "/0/token/Charges/Id/" + event.target.value
    // );
    this.vno.current.focus();
  };

  btnSave_onClick(event, formData) {
    let validationResult = this.validateCalculations(this.state.InvoiceDataL);

    if (validationResult == 1) {
      var today = formatDate();



        var GrossAmount = sum(this.state.InvoiceDataL);

        var TaxationAmount = taxsum(this.state.Tax);

        var NetAmount = this.state.NetAmount;
        console.log(formData.InvoiceId);
        if (formData.InvoiceId == "" || formData.InvoiceId == -1 || formData.InvoiceId== undefined) {
          //InvoiceH
       
          let vformData1 = new FormData();
          const obj = JSON.parse(localStorage.getItem("authUser"));
        
          vformData1.append("VoucherNo", this.state.VoucherNo);
          vformData1.append("VoucherDate", this.state.VoucherDate);
          vformData1.append("F_LedgerMaster", this.state.LedgerId); 
          vformData1.append("Rate", this.state.Rate);
          vformData1.append("PartyDetail", "");
          vformData1.append("Info", "");
          vformData1.append("EntryMode", 'Direct');
          vformData1.append("DiscountType", this.state.DiscountType);

          //Amount
          vformData1.append("TotalQty", this.state.TotalQuantity);
          vformData1.append("GrossAmount", GrossAmount);
          vformData1.append("TaxationAmount", TaxationAmount);
          vformData1.append("NetAmount", NetAmount);
         

 
     


          Fn_AddEditDataH(
            this.obj,
            { arguList: { id: 0, formData: vformData1 } },
            this.API_URL_SAVEH,
            this.pushFormName,
            true,
            "Id",
            JSON.stringify(this.state.InvoiceDataL),
            JSON.stringify(this.state.Tax),
            this.state.Charges,
            this.state.GSTTotalTaxAmount,
            "#",
            "EstimateL/0/token",
            "/estimateinvoice",
            'Estimate'
          );

         
        } else {
          this.setState({
            save: true,
            einvoice: false,
            InvoiceSel: false,
            cancel: true,
            add: false,
            print: false,
          });

          //InvoiceH
          let vformData1 = new FormData();

          console.log(this.state.VoucherNo);
          vformData1.append("VoucherNo", this.state.VoucherNo);
          vformData1.append("VoucherDate", this.state.VoucherDate);
          vformData1.append("F_LedgerMaster", this.state.LedgerId); 
          vformData1.append("Rate", this.state.Rate);
          vformData1.append("PartyDetail", "");
          vformData1.append("Info", "");
          vformData1.append("EntryMode", 'Direct');
          vformData1.append("DiscountType", this.state.DiscountType);

          //Amount
          vformData1.append("TotalQty", this.state.TotalQuantity);
          vformData1.append("GrossAmount", GrossAmount);
          vformData1.append("TaxationAmount", TaxationAmount);
          vformData1.append("NetAmount", NetAmount);

          Fn_AddEditData(
            this.obj,
            { arguList: { id: this.state.InvoiceId, formData: vformData1 } },
            this.API_URL_SAVEH,
            this.pushFormName,
            true
          );

          //InvoiceL
          let vformData = new FormData();

          vformData.append("F_InvoiceMasterH", formData.InvoiceId);
          vformData.append("Data", JSON.stringify(this.state.InvoiceDataL));

          Fn_AddEditData(
            this.obj,
            { arguList: { id: 0, formData: vformData } },
            this.API_URL_SAVE,
            this.pushFormName,
            true
          );

          let vformData2 = new FormData();

          vformData2.append("F_InvoiceMasterH", formData.InvoiceId);
          vformData2.append("Data", JSON.stringify(this.state.Tax));

          // Fn_AddEditData(
          //   this.obj,
          //   { arguList: { id: 0, formData: vformData2 } },
          //   this.API_URL_SAVETAX,
          //   this.pushFormName,
          //   true
          // );

          if (this.state.GSTTotalTaxAmount.length > 0) {
            let vformData4 = new FormData();

            vformData4.append("F_InvoiceMasterH", formData.InvoiceId);
            vformData4.append(
              "Data",
              JSON.stringify(this.state.GSTTotalTaxAmount)
            );

            // Fn_AddEditData(
            //   this.obj,
            //   { arguList: { id: 0, formData: vformData4 } },
            //   this.API_URL_TAXABLEAMOUNT,
            //   this.pushFormName,
            //   true
            // );
          }

          if (this.state.Charges.length > 0) {
            let vformData3 = new FormData();

            vformData3.append("F_InvoiceMasterH", formData.InvoiceId);
            vformData3.append("Data", JSON.stringify(this.state.Charges));

            Fn_AddEditData(
              this.obj,
              { arguList: { id: 0, formData: vformData3 } },
              this.API_URL_SAVECHARGE,
              this.pushFormName,
              true
            );
          }
        }
    
    }

  }

  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  valueset(name, evnt) {
      console.log(this.state.VoucherNo)
    if (name === "VoucherNo"){
      
       this.setState({ VoucherNo: evnt.target.value })
      }
    else if (name === "VoucherDate")
      this.setState({ VoucherDate: evnt.target.value });
    else if (name === "ChallanNo")
      this.setState({ ChallanNo: evnt.target.value });
    else if (name === "ChallanDate")
      this.setState({ ChallanDate: evnt.target.value });
    else if (name === "DiscountType"){
      this.setState({ DiscountType: evnt.target.value });


    }
    else if (name === "EntryMode") this.setState({ EntryMode: evnt.target.value });
    else if (name === "Rate") {
      let val = evnt.target.value;
      this.setState({ Rate: evnt.target.value });
    
      // Initialize variables
      let ItemId = 0;
      let item = null;
    
      
      for (let i = 0; i < this.state.InvoiceDataL.length; i++) {
        ItemId = this.state.InvoiceDataL[i].ItemName;
        
        item = this.state.ItemName[i].find((item) => item.Id == ItemId); 
        if (!ItemId || !item) {
          continue; 
        }
        
        if (val === "Rate1" ) {
          const rowsInput = [...this.state.InvoiceDataL];
          const { Rate1 } = item;
          console.log(Rate1)
          rowsInput[i]['Rate'] = Rate1;
       
          this.setState({ InvoiceDataL: rowsInput,RateForSend : Rate1 });
        } else if (val === "Rate2") {
          const rowsInput = [...this.state.InvoiceDataL];
          const { Rate2 } = item;
          console.log(Rate2)
          rowsInput[i]['Rate'] = Rate2;
          this.setState({ InvoiceDataL: rowsInput,RateForSend : Rate2 });
        } else if (val === "Rate3" ) {
          const rowsInput = [...this.state.InvoiceDataL];
          const { Rate3 } = item;
          console.log(Rate3)
          rowsInput[i]['Rate'] = Rate3;
          this.setState({ InvoiceDataL: rowsInput ,RateForSend : Rate3});
        }
         else if (val === "PurchaseRate" ) {
          const rowsInput = [...this.state.InvoiceDataL];
          const { PurchaseRate } = item;
          
          rowsInput[i]['Rate'] = PurchaseRate;
          this.setState({ InvoiceDataL: rowsInput,RateForSend : PurchaseRate });
        }
        const rowsInput = [...this.state.InvoiceDataL];
        var Rate = rowsInput[i]["Rate"];

        var Qty = rowsInput[i]["Qty"];
  
        var Amount = parseFloat(Rate) * parseFloat(Qty);
  
        Amount = parseFloat(Amount).toFixed(2);
  
        rowsInput[i]["Amount"] = Amount;
        this.setState({ InvoiceDataL: rowsInput });
      }
      this.gst();
    }
    
    else if (name === "PartyDetail") this.setState({ PartyDetail : evnt.target.value });
    else if (name === "LedgerId")
      this.setState({ LedgerId: evnt.target.value });
    else if (name === "SalesLedgerId")
      this.setState({ SalesLedgerId: evnt.target.value });
    else if (name === "Info")
      this.setState({ Info: evnt.target.value });
    else if (name === "VehicleNo")
      this.setState({ VehicleNo: evnt.target.value });
    else if (name === "AddInfo") this.setState({ AddInfo: evnt.target.value });
    else if (name === "PartyDetail")
      this.setState({ PartyDetail: evnt.target.value });
    else if (name === "IsDiff") this.setState({ IsDiff: evnt.target.value });
    else if (name === "Add1") this.setState({ Add1: evnt.target.value });
    else if (name === "Add2") this.setState({ Add2: evnt.target.value });
    else if (name === "Add3") this.setState({ Add3: evnt.target.value });
    else if (name === "OtherAddress")
      this.setState({ OtherAddress: evnt.target.value });
  }

  handler2 = (index, evnt) => {
    if (evnt.key == "Enter") {
      if (index == "VoucherNo") this.vno.current.focus();
      else if (index == "VoucherDate") this.vdate.current.focus();
      else if (index == "Sinvoice") this.Sinvoice.current.focus();
      else if (index == "invoice") this.invoice.current.focus();
      else if (index == "ChallanNo") {
        this.chno.current.focus();
      } else if (index == "ChallanDate") this.chdate.current.focus();
      else if (index == "PONo") this.pono.current.focus();
      else if (index == "PODate") this.podate.current.focus();
      else if (index == "GRRNo") this.grrno.current.focus();
      else if (index == "GRRDate") this.grrdate.current.focus();
      else if (index == "LedgerId") this.ledger.current.focus();
      else if (index == "SalesLedgerId") this.salesledger.current.focus();
      else if (index == "Transport") this.transport.current.focus();
      else if (index == "VehicleNo") this.vhno.current.focus();
      else if (index == "Through") this.through.current.focus();
      else if (index == "Signatory") this.signatory.current.focus();
      else if (index == "IsDiff") this.diffcon.current.focus();
      else if (index == "Add1") {
        if (this.state.IsDiff == true) this.IsAdd1.current.focus();
        else {
          //alert("Press 2 Tabs to procceed item list!");
          this.Code[0].current.focus();
        }
      } else if (index == "TranMode") {
        this.tranmode.current.focus();
      } else if (index == "TranDist") {
        this.trandist.current.focus();
      } else if (index == "done") {
        alert("Press 2 Tabs to procceed item list!");
      } else if (index == "Add2") this.IsAdd2.current.focus();
      else if (index == "Add3") this.IsAdd3.current.focus();
      else if (index == "Add4") {
        this.TranId.current.focus();
      }

      evnt.preventDefault();
    }
  };

  delete() {
    this.setState({ deletedlg: false });
    const obj = JSON.parse(localStorage.getItem("authUser"));
    if(obj.UserType!='Admin'){
      return;
    }
    Fn_DeleteData(
      this.obj,
      this.state.InvoiceId,
      "Masters/0/token/EstimateMasterH",
      "Masters/0/token/Estimate" + "/Id/0"
    );
  }

  proceedeinvoice() {
    this.setState({ oldvouhcerdate: false });

    var VoucherNo = this.state.VoucherNo;
    let vformData3 = new FormData();
    vformData3.append("VoucherNo", VoucherNo);

    var data = { arguList: { id: 0, formData: vformData3 } };

    var apiURL = "CheckInvoice/0/token";
    const { arguList } = data;
    const request = {
      arguList: arguList,
      apiURL: apiURL,
      callback: response => {
        if (response.data.response[0].Id > 0) {
          showToastWithCloseButton(
            "error",
            "E-Invoice Already Registered. AckNo - " +
              response.data.response[0].Id
          );
        } else {
          let vformData4 = new FormData();

          vformData4.append("F_InvoiceMasterH", this.state.InvoiceId);

          const requestOptions = {
            method: "POST",
            body: vformData4,
          };
          var url = API_WEB_URLS.BASE + "EInvoice/0/token";
          fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
              if (data.data.data.id == 1 || data.data.data.id == "1") {
                showToastWithCloseButton(
                  "success",
                  "E-Invoice Created Successfully!"
                );
              } else {
                showToastWithCloseButton("success", data.data.data.name);
              }
            })
            .catch(error => {
              showToastWithCloseButton("error", "Somthing went wrong");
            });
        }
      },
    };
    this.props.callAdd_Data_Multipart(request);
  }

  einvoice() {
    const VoucherDate = this.state.VoucherDate;

    const currentDate = new Date();
    const givenDate = new Date(VoucherDate);

    const isToday = givenDate.toDateString() === currentDate.toDateString();

    if (isToday == true) {
      this.proceedeinvoice();
    } else {
      this.setState({ oldvouhcerdate: true });
    }
  }

  focusTextInput() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    this.textInput.current.focus();
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  searchinvoice(event) {
    Fn_FillListData(
      this.obj,
      "Invoice",
      API_WEB_URLS.MASTER + "/0/token/SearchEst/Id/" + event.target.value
    );
  }

  changeINV(name, value) {
   
  }

  render() {
    var parameters = [
      {
        name: "Id",
        values: [this.state.InvoiceId],
      },
    ];
    const options = [
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      ,
      {
        label: "3",
        value: 3,
      },
      {
        label: "4",
        value: 4,
      },
    ];

    return (
      <React.Fragment>
        <div
          className="page-content"
          style={{
            backgroundColor:
              this.state.VoucherGlobalOptions[0].BackgroundColor != "" &&
              this.state.VoucherGlobalOptions[0].BackgroundColor != undefined &&
              this.state.VoucherGlobalOptions[0].BackgroundColor != null
                ? this.state.VoucherGlobalOptions[0].BackgroundColor
                : "white",
          }}
        >
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px', gap: '10px' }}>
      <span style={{ padding: '5px 10px', cursor: 'pointer', display: 'inline-block' }}>
        <span style={{ color: 'red', fontWeight: 'bold' }}>P</span> for Print
      </span>
      <span style={{ padding: '5px 10px', cursor: 'pointer', display: 'inline-block' }}>
        <span style={{ color: 'red', fontWeight: 'bold' }}>E</span> for Edit
      </span>
      <span style={{ padding: '5px 10px', cursor: 'pointer', display: 'inline-block' }}>
        <span style={{ color: 'red', fontWeight: 'bold' }}>A</span> for Add
      </span>
      <span style={{ padding: '5px 10px', cursor: 'pointer', display: 'inline-block' }}>
        <span style={{ color: 'red', fontWeight: 'bold' }}>C</span> for Cancel
      </span>
      <span style={{ padding: '5px 10px', cursor: 'pointer', display: 'inline-block' }}>
        <span style={{ color: 'red', fontWeight: 'bold' }}>I</span> for DropDown
      </span>
      <span style={{ padding: '5px 10px', cursor: 'pointer', display: 'inline-block' }}>
        <span style={{ color: 'red', fontWeight: 'bold' }}>X</span> for CopyEstimate
      </span>
      <span style={{ padding: '5px 10px', cursor: 'pointer', display: 'inline-block' }}>
        <span style={{ color: 'red', fontWeight: 'bold' }}>Delete</span>
      </span>
    </div>
          <Container fluid>
            {/* Render Breadcrumb */}

            <Row>
              <Col lg="12">
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.btnSave_onClick}
                >
                  <Card
                    style={{
                      backgroundColor:
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          "" &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          undefined &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          null
                          ? this.state.VoucherGlobalOptions[0].BackgroundColor
                          : "#f1ced4",
                    }}
                  >
                    <CardBody>
                      <Col lg="12">
                     
                        <Row style={{ marginTop: -10 }}>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Select Invoice
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="SInvoice"
                              ref={this.Sinvoice}
                              onKeyPress={evnt =>
                                this.handler2("invoice", evnt)
                              }
                              onChange={evnt => this.searchinvoice(evnt)}
                              placeholder="Search By Invoice No."
                              value={this.state.SInvoice}
                              type="text"
                              className="form-control"
                            ></input>
                          </Col>
                          <Col sm="3">
                          
                            <select
                              name="InvoiceId"
                              onKeyPress={evnt =>
                                this.handler2("VoucherNo", evnt)
                              }
                              ref={this.invoice}
                              onChange={this.OnInvoiceChange}
                              style={{ "font-size": 12 }}
                              label=""
                              value={
                                this.state.formData.InvoiceId === null
                                  ? "-1"
                                  : this.state.formData.InvoiceId
                              }
                              type="select"
                              className="form-select"
                              disabled={this.state.InvoiceSel}
                            >
                              <option
                                value={-1}
                                defaultValue
                                label={"Select"}
                              />
                              {this.state.Invoice
                                ? this.state.Invoice.map((option, key) => (
                                    <option
                                      key={option.Id}
                                      value={option.Id}
                                      label={option.Name}
                                    />
                                  ))
                                : null}
                            </select>
                          </Col>

                          {/* <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Entry Mode
                            </label>
                          </Col>
                          <Col sm="2">
                            <AvRadioGroup
                              name="EntryMode"
                              value={
                                this.state.EntryMode
                              }
                              onChange={evnt =>
                                this.valueset("EntryMode", evnt)
                              }
                            >
                              <AvRadio label="Bar Code" value="barcode" />
                              <AvRadio label="Direct" value="direct" />
                            </AvRadioGroup>
                          </Col> */}
                          <Col sm="2" >
                            <Label htmlFor="options" className="col-form-label">
                             DiscountType
                            </Label>
                          </Col>
                          <Col sm="2"  style={{width:'4px'}}>
                          <AvRadioGroup
                              
                              name="DiscountType"
                              value={
                                this.state.DiscountType
                              }
                              onChange={evnt =>
                                this.valueset("DiscountType", evnt)
                              }
                              inline
                            >
                              <AvRadio label="Flat" value="Flat" />
                              <AvRadio label="Percent" value="Percent" />
                            
                            </AvRadioGroup>
                           
                          </Col>
                          <Col style={{marginLeft:'90px'}}>
                          <Button
                          type="button"
                          color="danger"
                          // disabled={this.state.delete}
                          style={{ marginBottom: 10,marginLeft: 50 }}
                          onClick={this.copyEstimate}
                          className="mr-1 waves-effect waves-light"
                        >
                          copy Estimate
                        </Button>
                          </Col>
                          {/* <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Is DBN
                            </label>
                          </Col>
                          <Col sm="1">
                            <input
                              name="IsDBN"
                              onChange={evnt => this.changeINV("DBN", evnt)}
                              label=""
                              checked={this.state.IsDBN}
                              type="checkbox"
                            />
                          </Col> */}
                        </Row>

                        <Row style={{ marginTop: -14 }}>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              V No.
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              className="form-control"
                              type="text"
                              name="VoucherNo"
                              ref={this.vno}
                              onKeyPress={evnt =>
                                this.handler2("VoucherDate", evnt)
                              }
                              value={this.state.VoucherNo}
                              onChange={evnt =>
                                this.valueset("VoucherNo", evnt)
                              }
                              disabled={
                                this.state.VoucherGlobalOptions[0]
                                  .IsVoucherNoEnable
                              }
                            />
                          </Col>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              V Date
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="VoucherDate"
                              onChange={evnt =>
                                this.valueset("VoucherDate", evnt)
                              }
                              onKeyPress={evnt =>
                                this.handler2("LedgerId", evnt)
                              }
                              ref={this.vdate}
                              label=""
                              value={this.state.VoucherDate}
                              type="date"
                              className="form-control"
                            ></input>
                          </Col>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Rate
                            </label>
                          </Col>
                          <Col sm="4">
                            <AvRadioGroup
                              name="Rate"
                              value={
                                this.state.Rate
                              }
                              onChange={evnt =>
                                this.valueset("Rate", evnt)
                              }
                              inline
                            >
                              <AvRadio label="Rate1" value="Rate1" />
                              <AvRadio label="Rate2" value="Rate2" />
                              <AvRadio label="Rate3" value="Rate3" />
                              <AvRadio label="Purchase Rate" value="PurchaseRate" />
                            </AvRadioGroup>
                          </Col>
                         
                      
                        </Row>

                        <Row  >
                        <Col sm="1">
                            <label
                              htmlFor="LedgerId"
                              className="col-form-label"
                            >
                              Party
                            </label>
                          </Col>
                          <Col sm="5" >
                          <select
                              name="LedgerId"
                              ref={this.ledger}
                              onKeyPress={evnt =>
                                this.handler2("PartyDetail", evnt)
                              }
                              onChange={this.OnLedgerChange}
                              style={{ fontSize: 12 }}
                              value={this.state.LedgerId}
                              className="form-select"
                            >
                              <option value={-1} defaultValue>
                                Select
                              </option>
                              {this.state.Ledger &&
                                this.state.Ledger.map((option, key) => (
                                  <option key={option.Id} value={option.Id}>
                                    {option.Name}
                                  </option>
                                ))}
                            </select>
                          </Col>
                         
                          {/* <Col sm="2" className="mb-3" >
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              PartyDetail
                            </label>
                          </Col>
                          <Col sm="4">
                            <AvField
                              name="PartyDetail"
                              onChange={evnt =>
                                this.valueset("PartyDetail", evnt)
                              }
                              ref={this.signatory}
                              onKeyPress={evnt =>
                                this.handler2("AddInfo", evnt)
                              }
                              label=""
                              value={this.state.PartyDetail}
                              type="text"
                              className="form-control"
                            ></AvField>
                          </Col> */}
                        
                        </Row>
                        
                          {/* <Row>
                          <Col sm="2" className="mb-3">
                            <label htmlFor="Info" className="col-form-label">
                              AddInfo
                            </label>
                          </Col>
                          <Col sm="4">
                            <AvField
                              name="Info"
                              onChange={evnt => this.valueset("Info", evnt)}
                              ref={this.through}
                              onKeyPress={evnt =>
                                this.handler2("PartyDetail", evnt)
                              }
                              label=""
                              value={this.state.Info}
                              type="text"
                              className="form-control"
                            ></AvField>
                          </Col>
                            </Row>          */}
                        <Row
                          style={{ marginTop: -14 }}
                          hidden={this.state.IsCon}
                        >
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Add1
                            </label>
                          </Col>
                          <Col sm="1">
                            <input
                              name="Add1"
                              ref={this.IsAdd1}
                              onKeyPress={evnt => this.handler2("Add2", evnt)}
                              onChange={this.getAddress}
                              label=""
                              checked={this.state.Add1}
                              type="checkbox"
                            ></input>
                          </Col>

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Add2
                            </label>
                          </Col>
                          <Col sm="1">
                            <input
                              name="Add2"
                              ref={this.IsAdd2}
                              onKeyPress={evnt => this.handler2("Add3", evnt)}
                              onChange={this.getAddress}
                              label=""
                              checked={this.state.Add2}
                              type="checkbox"
                            ></input>

                            {/* <AvField name="Add2"   onChange={this.getAddress}   label=""  value={this.state.formData.Add2 === null ? this.state.Add2   : this.state.Add2} checked={this.state.Add2} type="checkbox" className="form-control" >
                                          </AvField> */}
                          </Col>

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Add3
                            </label>
                          </Col>
                          <Col sm="1">
                            <input
                              name="Add3"
                              ref={this.IsAdd3}
                              onKeyPress={evnt => this.handler2("TranId", evnt)}
                              onChange={this.getAddress}
                              label=""
                              checked={this.state.Add3}
                              type="checkbox"
                            ></input>
                            {/* <AvField name="Add3"   onChange={this.getAddress}   label=""  value={this.state.formData.Add3 === null ? this.state.Add3   : this.state.Add3} checked={this.state.Add3} type="checkbox" className="form-control" >
                                          </AvField> */}
                          </Col>

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Oth.
                            </label>
                          </Col>

                          <Col sm="4">
                            <input
                              name="OtherAddress"
                              onChange={evnt =>
                                this.valueset("OtherAddress", evnt)
                              }
                              onKeyPress={evnt => this.handler2("TranId", evnt)}
                              label=""
                              value={
                                this.state.OtherAddress === null
                                  ? ""
                                  : this.state.OtherAddress
                              }
                              type="text"
                              className="form-control"
                            ></input>
                            {/* <AvField name="OtherAddress"    label=""  value={this.state.OtherAddress === null ? '' : this.state.OtherAddress}  type="text" className="form-control" disabled>
                                          </AvField> */}
                          </Col>
                        </Row>
                      </Col>
                    </CardBody>
                  </Card>
                  <Card
                    style={{
                      backgroundColor:
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          "" &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          undefined &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          null
                          ? this.state.VoucherGlobalOptions[0].BackgroundColor
                          : "#92d2e8",
                    }}
                  >
                    <CardBody>
                      <AddDeleteTableRows
                        itemName={this.state.ItemName}
                        data={this.state.InvoiceDataL}
                        add={this.addrow}
                        edit={this.editrow}
                        deleter={this.deleterow}
                        GroupMaster={this.state.GroupMaster}
                        UnitMaster={this.state.UnitMaster}
                        Code={this.Code}
                      />
                    </CardBody>
                  </Card>

                  <Card
                    style={{
                      backgroundColor:
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          "" &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          undefined &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          null
                          ? this.state.VoucherGlobalOptions[0].BackgroundColor
                          : "rgb(233, 207, 241)",
                    }}
                  >
                    <CardBody>
                      <Row>
                        {/* <Col sm="4">
                          <AddDeleteTableRowsCharges
                            data={this.state.Charges}
                            addTableRows={this.addchargesrow}
                            Ledger={this.state.ChargesLedger}
                            edit={this.editChargesrow}
                          />
                        </Col> */}

                        {/* <Col sm="4">
                          <AddDeleteTableRowsTax data={this.state.Tax} />
                        </Col> */}

                        <Col sm="8">
                          <Row>
                            <Col sm="2">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Total Quantity
                              </label>
                            </Col>
                            <Col sm="3">
                              <AvField
                                name="TotalQuantity"
                                label=""
                                value={this.state.TotalQuantity}
                                type="text"
                                className="form-control"
                                disabled
                              ></AvField>
                            </Col>
                            <Col sm="2" className="mb-3">
                              <label
                                htmlFor="GrossAmount"
                                className="col-form-label"
                              >
                                Gross Amount
                              </label>
                            </Col>
                            <Col sm="3">
                              <AvField
                                name="GrossAmount"
                                label=""
                                value={this.state.GrossAmount}
                                type="text"
                                className="form-control"
                                disabled
                              ></AvField>
                            </Col>
                          </Row>
                        

                          {/* <Row>
                            <Col sm="2" className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Tax Amount
                              </label>
                            </Col>
                            <Col sm="3">
                              <AvField
                                name="TaxAmount"
                                label=""
                                value={this.state.TaxAmount}
                                type="text"
                                className="form-control"
                                disabled
                              ></AvField>
                            </Col>
                            <Col sm="2" className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Net. Amt.
                              </label>
                            </Col>
                            <Col sm="3">
                              <AvField
                                name="NetAmount"
                                label=""
                                value={this.state.NetAmount}
                                type="text"
                                className="form-control"
                                disabled
                              ></AvField>
                            </Col>
                          </Row> */}
                          
                          {/* <Row>
                            <Col sm="4" className="mb-3">
                              <label
                                htmlFor="GRAmount"
                                className="col-form-label"
                              >
                                G.R. Amount
                              </label>
                            </Col>
                            <Col sm="5">
                              <AvField
                                name="GRAmount"
                                label=""
                                value={this.state.GRAmount}
                                type="text"
                                className="form-control"
                                disabled
                              ></AvField>
                            </Col>
                          </Row> */}
                          
                        </Col>
                      </Row>

                      {/* <Row style={{ marginTop: -14 }}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ width: "60px" }}> EWAY</th>
                              <th>
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  onClick={() => {
                                    this.setState({ IsEway: true });
                                  }}
                                >
                                  +
                                </button>
                              </th>
                            </tr>
                          </thead>
                        </table>

                        {this.state.IsEway == true ? (
                          <>
                            <Col sm="1" className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Trans. Id
                              </label>
                            </Col>
                            <Col sm="2">
                              <input
                                name="TransportId"
                                ref={this.TranId}
                                onKeyPress={evnt =>
                                  this.handler2("TranMode", evnt)
                                }
                                onChange={e =>
                                  Fn_ChangeStateValue(
                                    this.obj,
                                    "TransportId",
                                    e.target.value
                                  )
                                }
                                label=""
                                value={this.state.TransportId}
                                type="text"
                                className="form-control"
                              ></input>
                            </Col>
                            <Col sm="1" className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Mode
                              </label>
                            </Col>
                            <Col sm="2">
                              <select
                                name="TransportMode"
                                ref={this.tranmode}
                                onKeyPress={evnt =>
                                  this.handler2("TranDist", evnt)
                                }
                                menuIsOpen={true}
                                onChange={e =>
                                  Fn_ChangeStateValue(
                                    this.obj,
                                    "TransportMode",
                                    e.target.value
                                  )
                                }
                                value={this.state.TransportMode}
                                type="select"
                                className="form-select"
                              >
                                <option
                                  value={-1}
                                  defaultValue
                                  label={"Select Transport Mode"}
                                />
                                <option value={1} label={"Road"} />
                                <option value={2} label={"Rail"} />
                                <option value={3} label={"Air"} />
                                <option value={4} label={"Ship"} />
                              </select>
                            </Col>

                            <Col sm="1" className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Trans. Dist.
                              </label>
                            </Col>
                            <Col sm="2">
                              <input
                                name="TransportDistance"
                                ref={this.trandist}
                                onKeyPress={evnt => this.handler2("done", evnt)}
                                onChange={e =>
                                  Fn_ChangeStateValue(
                                    this.obj,
                                    "TransportDistance",
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control"
                              />
                            </Col>
                          </>
                        ) : null}
                      </Row> */}
                    </CardBody>
                  </Card>

                  <Card
                    style={{
                      backgroundColor:
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          "" &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          undefined &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          null
                          ? this.state.VoucherGlobalOptions[0].BackgroundColor
                          : "#a8ead4",
                    }}
                  >
                    <CardBody>
                      <div>
                        <Button
                          type="button"
                          disabled={this.state.add}
                          color="danger"
                          ref={this.AddBtn}
                          style={{ marginLeft: 10 }}
                          onClick={this.add}
                          className="mr-1 waves-effect waves-light"
                        >
                          Add
                        </Button>

                        <Button
                          type="button"
                          color="danger"
                          disabled={this.state.edit}
                          style={{ marginLeft: 10 }}
                          onClick={this.edit}
                          className="mr-1 waves-effect waves-light"
                        >
                          {this.state.editbtnname}
                        </Button>
                        <Button
                          disabled={this.state.save}
                          type="submit"
                          color="danger"
                          style={{ marginLeft: 10 }}
                          className="mr-1 waves-effect waves-light"
                        >
                          Save
                        </Button>

                        <Button
                          type="button"
                          color="danger"
                          disabled={this.state.cancel}
                          style={{ marginLeft: 10 }}
                          onClick={this.cancel}
                          className="mr-1 waves-effect waves-light"
                        >
                          Cancel
                        </Button>

                        {API_WEB_URLS.Customer == 10 ||
                        API_WEB_URLS.Customer == 9 ? null : (
                          <Button
                            type="button"
                            color="danger"
                            disabled={this.state.einvoice}
                            style={{ marginLeft: 10 }}
                            onClick={this.einvoice}
                            className="mr-1 waves-effect waves-light"
                          >
                            E-Invoice
                          </Button>
                        )}

                        <Button
                          type="button"
                          color="danger"
                          disabled={this.state.print}
                          style={{ marginLeft: 10 }}
                          onClick={this.tog_fullscreen}
                          className="mr-1 waves-effect waves-light"
                        >
                          Print
                        </Button>

                        <Button
                          type="button"
                          color="danger"
                          // disabled={this.state.delete}
                          style={{ marginLeft: 10 }}
                          onClick={this.copy}
                          className="mr-1 waves-effect waves-light"
                        >
                          Copy
                        </Button>

                        <Button
                          type="button"
                          color="danger"
                          //  disabled={this.state.delete}
                          style={{ marginLeft: 10 }}
                          onClick={this.paste}
                          className="mr-1 waves-effect waves-light"
                        >
                          Paste
                        </Button>

                        <Button
                          type="button"
                          color="danger"
                          disabled={this.state.delete}
                          style={{ marginLeft: 10 }}
                          onClick={() => this.setState({ deletedlg: true })}
                          className="mr-1 waves-effect waves-light"
                        >
                          Delete

                        </Button>

                        {API_WEB_URLS.Customer == 10 ||
                        API_WEB_URLS.Customer == 9 ? null : (
                          <Button
                            type="button"
                            color="danger"
                            disabled={this.state.canceleinvoiceBtn}
                            style={{ marginLeft: 10 }}
                            onClick={() =>
                              this.setState({ canceleinvoice: true })
                            }
                            className="mr-1 waves-effect waves-light"
                          >
                            {this.state.cancelinvoiceName}
                          </Button>
                        )}

                        {this.state.success_msg ? (
                          <SweetAlert
                            title="Data Saved Successfully!"
                            success
                            confirmBtnBsStyle="success"
                            onConfirm={this.syno}
                          >
                            You clicked the button!
                          </SweetAlert>
                        ) : null}

                        {this.state.canceleinvoice ? (
                          <>
                            <SweetAlert
                              warning
                              showCancel
                              confirmBtnText="Yes, Cancel it!"
                              confirmBtnBsStyle="success"
                              cancelBtnBsStyle="danger"
                              onConfirm={() => this.canceleinvoice()}
                              onCancel={() =>
                                this.setState({ canceleinvoice: false })
                              }
                              title="Are you sure to cancel ?"
                            >
                              <Col sm="2" className="mb-3">
                                <label
                                  htmlFor="maritalStatus"
                                  className="col-form-label"
                                >
                                  VoucherNo:{" "}
                                  {this.state.InvoiceData[0].VoucherNo}
                                </label>
                              </Col>
                              <Col sm="4" className="mb-3">
                                <label
                                  htmlFor="maritalStatus"
                                  className="col-form-label"
                                >
                                  Cancel Reason
                                </label>
                              </Col>

                              <Col sm="8" className="mb-3">
                                <select
                                  name="CancelReasonId"
                                  menuIsOpen={true}
                                  onChange={e =>
                                    Fn_ChangeStateValue(
                                      this.obj,
                                      "CancelReasonId",
                                      e.target.value
                                    )
                                  }
                                  value={this.state.CancelReasonId}
                                  type="select"
                                  className="form-select"
                                >
                                  <option
                                    value={-1}
                                    defaultValue
                                    label={"Select Reason"}
                                  />
                                  <option value={1} label={"Duplicate"} />
                                  <option
                                    value={2}
                                    label={"Data Entry Mistake"}
                                  />
                                  <option value={3} label={"Order Cancel"} />
                                  <option value={4} label={"Others"} />
                                </select>
                              </Col>

                              <Col sm="4" className="mb-3">
                                {" "}
                                <label
                                  htmlFor="maritalStatus"
                                  className="col-form-label"
                                >
                                  Reason Remarks
                                </label>
                              </Col>
                              <Col sm="8" className="mb-3">
                                <input
                                  name="ReasonRemarks"
                                  onChange={e =>
                                    Fn_ChangeStateValue(
                                      this.obj,
                                      "ReasonRemarks",
                                      e.target.value
                                    )
                                  }
                                  label="Reason Remarks"
                                  value={
                                    this.state.ReasonRemarks == ""
                                      ? this.state.ReasonRemarks
                                      : this.state.ReasonRemarks
                                  }
                                  type="text"
                                  className="form-control"
                                ></input>
                              </Col>
                            </SweetAlert>
                          </>
                        ) : null}

                        {this.state.oldvouhcerdate ? (
                          <>
                            <SweetAlert
                              warning
                              showCancel
                              confirmBtnText="Yes, Proceed!"
                              confirmBtnBsStyle="success"
                              cancelBtnBsStyle="danger"
                              onConfirm={() => this.proceedeinvoice()}
                              onCancel={() =>
                                this.setState({ oldvouhcerdate: false })
                              }
                              title="Old VoucherDate Are you sure to Proceed ?"
                            ></SweetAlert>
                          </>
                        ) : null}

                        {this.state.deletedlg ? (
                          <>
                            <SweetAlert
                              warning
                              showCancel
                              confirmBtnText="Yes, Proceed!"
                              confirmBtnBsStyle="success"
                              cancelBtnBsStyle="danger"
                              onConfirm={() => this.delete()}
                              onCancel={() =>
                                this.setState({ deletedlg: false })
                              }
                              title="Are you sure to delete ?"
                            >
                              Data will can't be retrieve !
                            </SweetAlert>
                          </>
                        ) : null}
                      </div>
                    </CardBody>
                  </Card>

                  <Card
                    style={{
                      backgroundColor:
                        API_WEB_URLS.Customer === 4
                          ? this.state.VoucherGlobalOptions[0].BackgroundColor
                          : "#a8ead4",
                    }}
                  >
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>

                      <div>
                        <Modal
                          size="xl"
                          isOpen={this.state.modal_fullscreen}
                          toggle={this.tog_fullscreen}
                          className="modal-fullscreen"
                        >
                          <div className="modal-header">
                            <h5
                              className="modal-title mt-0"
                              id="exampleModalFullscreenLabel"
                            >
                              Print
                            </h5>
                            <button
                              onClick={() =>
                                this.setState({ modal_fullscreen: false })
                              }
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                           
                              <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/Merchant/Estimate"}
                                printMode={true}
                                parameters={parameters}
                              ></BoldReportViewerComponent>
                           
                          
                          
                          
                          
                           
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              onClick={this.tog_fullscreen}
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </Modal>
                      </div>
                    </CardBody>
                  </Card>
                </AvForm>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_EstimateInvoice);
