import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardTitle, CardText, CardBody } from "reactstrap";

class Dashboard extends Component {
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = event => {
    switch (event.key) {
      case "F1":
        event.preventDefault();
        this.props.history.push("/estimateinvoice");
        break;
      case "F2":
        event.preventDefault();
        this.props.history.push("/addinvoice_new");
        break;
      case "F3":
        event.preventDefault();
        this.props.history.push("/purchasegoods");
        break;
      case "F4":
        event.preventDefault();
        this.props.history.push("/stockin");
        break;
      case "F5":
        event.preventDefault();
        this.props.history.push("/Ledger");
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Sahakaar Admin & Dashboard</title>
          </MetaTags>
          <Container fluid>
            {/* <h4>Dashboard</h4> */}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', fontSize: '16px' }}>
    <Card style={{ cursor: "pointer" }}>
      <CardBody>
        <span>Press <span style={{ color: 'red' }}>F1</span> for Estimate</span>
      </CardBody>
    </Card>
    <Card style={{ cursor: "pointer" }}>
      <CardBody>
        <span>Press <span style={{ color: 'red' }}>F2</span> for Invoice</span>
      </CardBody>
    </Card>
    <Card style={{ cursor: "pointer" }}>
      <CardBody>
        <span>Press <span style={{ color: 'red' }}>F3</span> for Purchase</span>
      </CardBody>
    </Card>
    <Card style={{ cursor: "pointer" }}>
      <CardBody>
        <span>Press <span style={{ color: 'red' }}>F4</span> for StockIn</span>
      </CardBody>
    </Card>
    <Card style={{ cursor: "pointer" }}>
      <CardBody>
        <span>Press <span style={{ color: 'red' }}>F5</span> for Ledger</span>
      </CardBody>
    </Card>
    
  </div>
            <div style={{ display: 'flex', fontSize: '16px'}}>
            <Card style={{ cursor: "pointer",float:'left',width:'18rem'  }}>
      <CardBody>
        <span>Press <span style={{ color: 'red' }}>F1</span> for Come back here!</span>
      </CardBody>
    </Card>
    
  </div>
  
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
