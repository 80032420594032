import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import Switch from "react-switch";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";

// Editable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
  Fn_ChangeStateValue,
  Fn_FillListDataLedger,
  Fn_DisplayDataLedger,
  Fn_DeleteData,
} from "../../store/functions";

export const DateString = dd => {
  let d1 = dd.replace("-", "");
  let d2 = d1.replace("-", "");

  return d2;
};

class pageAddEdit_Ledger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,

      formData: {},
      activeTab: 1,
      passedSteps: [1],
      BrowseImage1: "",
      BrowseImage2: "",
      UserImage: "",
      Signature: "",
      //dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],

      Name: "",
      CGST: "0",
      SGST: "0",
      IGST: "0",
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      memberid: 0,
      name: "",
      email: "",
      aid: 0,
      Zip: 0,
      PhoneNo: 0,
      fo3: false,
      Gender: 0,
      MaritalStatus: 0,
      success_msg: false,
      F_MISScheme: 0,
      IsOvertime: false,
      EmpBasic: [],
      IsValue: false,
      ShowGstType: false,
      showLedger: 1,
      LedgerGroupId: [
        {
          GroupType: 100,
        },
      ],
    };
    this.obj = this;
    this.formTitle = "Ledger";
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Ledger";
    this.API_URL_SAVE = API_WEB_URLS.Ledger + "/0/token";
    this.pushFormName = "/Ledger";
    this.rtPage_Print = "/membersynopsisprint";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.btnGoBack_onClick = this.btnGoBack_onClick.bind(this);
    this.btnDelete = this.btnDelete.bind(this);

    this.API_URLDEL = API_WEB_URLS.MASTER + "/0/token/Ledger";
  }

  btnDelete() {
    Fn_DeleteData(
      this.obj,
      this.state.id,
      this.API_URLDEL,
      this.API_URLDEL + "/Id/0"
    );
    this.props.history.push(`${this.pushFormName}`);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }
  handleKeyDown = event => {
    switch (event.key) {
      case "F1":
        event.preventDefault();
        this.props.history.push("/dashboard");
        break;
      default:
        break;
    }
  };
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);

    
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });

    //Filling DropDowns
    Fn_FillListData(
      this.obj,
      "LedgerGroup",
      API_WEB_URLS.MASTER + "/0/token/LedgerGroup/Id/0"
    );
    // Fn_FillListData(
    //   this.obj,
    //   "Country",
    //   API_WEB_URLS.MASTER + "/0/token/Country/Id/0"
    // );
    Fn_FillListData(
      this.obj,
      "State",
      API_WEB_URLS.MASTER + "/0/token/State/Id/0"
    );
    
    // Fn_FillListData(this.obj, "GST", API_WEB_URLS.MASTER + "/0/token/GST/Id/0");

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id, IsValue: true });
      this.breadCrumbItem = "Edit " + this.formTitle;

      Fn_DisplayDataLedger(this.obj, id, this.API_URL + "/Id");
      this.OnledgerChangeEdit();
    } else {
      this.setState({ id: 0 });
    }
  }

  btnSave_onClick(event, formData) {
    // Ensure consistent variable naming
    let vformData = new FormData();

    // Set default values for missing form fields
    const setDefault = (value) => {
      return (value === undefined || value === "" || value === null) ? 0 : value;
  };
  

    // Set default values for form fields
    var Zip = setDefault(formData.Zip);
    var Rate = setDefault(formData.Rate);
    var Phone = setDefault(this.state.PhoneNo);
    var CrDays = setDefault(formData.CrDays);
    var Mobile = setDefault(this.state.Mobile);
    var Nature = setDefault(formData.Nature);
    var GSTType = setDefault(formData.GSTType);
    var TaxAddLess = setDefault(formData.TaxAddLess);
    var Calculation = setDefault(formData.Calculation);
    var CreditLimit = setDefault(formData.CreditLimit);
    var F_CityMaster = setDefault(formData.F_CityMaster);
    var F_StateMaster = setDefault(formData.F_StateMaster);
    var RoundOffSales = setDefault(formData.RoundOffSales);
    var F_CountryMaster = setDefault(formData.F_CountryMaster);
    var F_LedgerGroupMaster = setDefault(formData.F_LedgerGroupMaster);
    var F_GSTGroupTypeMaster = setDefault(formData.F_GSTGroupTypeMaster);
    var Type = setDefault(formData.Type);
    var HSCCode = setDefault(formData.HSCCode);

    // Ensure correct usage of reused variables
    F_CountryMaster = setDefault(this.state.CountryId);
    F_StateMaster = setDefault(this.state.StateId);
    HSCCode = setDefault(this.state.HSCCode);

    // Log HSCCode for debugging
    console.log("HSCCode", HSCCode);

    // Append form data to vformData
    vformData.append("Name", this.state.Name);
    vformData.append("Alias", this.state.Alias);
    vformData.append("Address1", this.state.Address1);
    vformData.append("Address2", this.state.Address2);
    vformData.append("Phone", Phone);
    vformData.append("Mobile", Mobile);
    vformData.append("Email", this.state.Email);
    vformData.append("F_CityMaster", F_CityMaster);
    vformData.append("F_LedgerGroupMaster", F_LedgerGroupMaster);
    vformData.append("Zip", Zip);
    vformData.append("GSTNo", this.state.GSTIN);
    vformData.append("PANNo", formData.PANNo);
    vformData.append("Area", formData.Area);
    vformData.append("CrDays", CrDays);
    vformData.append("Rate", Rate);
    vformData.append("Type", Type);
    vformData.append("GSTType", GSTType);
    vformData.append("Calculation", Calculation);
    vformData.append("Nature", Nature);
    vformData.append("RoundOffSales", RoundOffSales);
    vformData.append("F_GSTGroupTypeMaster", F_GSTGroupTypeMaster);
    vformData.append("F_StateMaster", F_StateMaster);
    vformData.append("TaxAddLess", TaxAddLess);
    vformData.append("CreditLimit", CreditLimit);
    vformData.append("ContactPerson1", formData.ContactPerson1);
    vformData.append("ContactPerson1_Email", formData.ContactPerson1_Email);
    vformData.append("ContactPerson1_Mobile", formData.ContactPerson1_Mobile);
    vformData.append("ContactPerson2", formData.ContactPerson2);
    vformData.append("ContactPerson2_Email", formData.ContactPerson2_Email);
    vformData.append("ContactPerson2_Mobile", formData.ContactPerson2_Mobile);
    vformData.append("HSCCode", HSCCode);
    vformData.append("IsOwnState", formData.IsOwnState ? formData.IsOwnState : true);

    // Determine whether to add or edit data
    if (!this.state.id) {
        Fn_AddEditData(
            this.obj,
            { arguList: { id: 0, formData: vformData } },
            this.API_URL_SAVE,
            this.pushFormName,
            true,
            "memberid"
        );
    } else {
        Fn_AddEditData(
            this.obj,
            { arguList: { id: this.state.id, formData: vformData } },
            this.API_URL_SAVE,
            this.pushFormName,
            true,
            "kuku"
        );
    }
}


  btnGoBack_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  type() {
    if (this.state.ShowGstType == true)
      return (
        <>
          <Col sm="2" className="mb-3">
            <label htmlFor="country" className="col-form-label">
              GST Type
            </label>
          </Col>
          <Col sm="2">
            <AvField
              name="GSTType"
              label=""
              value={
                this.state.formData.GSTType === null
                  ? "-1"
                  : this.state.formData.GSTType
              }
              errorMessage="Select GST type"
              validate={{ required: { value: false } }}
              type="select"
              className="form-select"
            >
              <option value={0} defaultValue label={"Select"} />
              <option value={1} defaultValue label={"IGST"} />
              <option value={2} defaultValue label={"SGST"} />
              <option value={3} defaultValue label={"CGST"} />
            </AvField>
          </Col>
        </>
      );
    else return null;
  }

  Ledger() {
    const GSTType = this.type();
    if (this.state.LedgerGroupId[0].GroupType == 100) return null;
    else if (this.state.LedgerGroupId[0].GroupType == 8)
      return (
        <>
          <Col sm="2" className="mb-3">
            <label htmlFor="firstName" className="col-form-label">
              Name
            </label>
          </Col>
          <Col sm="6">
            <AvField
              name="Name"
              label=""
              onChange={event => this.OnNameChange("Name", event)}
              value={
                this.state.formData.Name === null
                  ? ""
                  : this.state.formData.Name
              }
              placeholder=" Name"
              errorMessage="Enter Name"
              validate={{ required: { value: false } }}
              type="Text"
              className="form-control"
            />
          </Col>

          <Col sm="2" className="mb-3">
            <label htmlFor="firstName" className="col-form-label">
              Alias
            </label>
          </Col>
          <Col sm="6">
            <AvField
              name="Alias"
              label=""
              onChange={event => this.OnNameChange("Alias", event)}
              value={this.state.formData.Alias}
              placeholder="Alias"
              errorMessage="Enter Alias"
              validate={{ required: { value: false } }}
              type="Text"
              className="form-control"
            />
          </Col>
        </>
      );
    else if (
      this.state.LedgerGroupId[0].GroupType == 12 ||
      this.state.LedgerGroupId[0].GroupType == 14 ||
      this.state.LedgerGroupId[0].GroupType == 15 ||
      this.state.LedgerGroupId[0].GroupType == 16
    )
      return (
        <>
          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Name
              </label>
            </Col>
            <Col sm="6">
              <AvField
                name="Name"
                label=""
                onChange={event => this.OnNameChange("Name", event)}
                value={this.state.Name}
                placeholder=" Name"
                errorMessage="Enter Name"
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>
          </Row>

          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Alias
              </label>
            </Col>
            <Col sm="6">
              <AvField
                name="Alias"
                label=""
                onChange={event => this.OnNameChange("Alias", event)}
                value={this.state.formData.Alias}
                placeholder="Alias"
                errorMessage="Enter Alias"
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>
          </Row>

          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Rate
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="Rate"
                label=""
                value={this.state.formData.Rate}
                placeholder="0"
                errorMessage="Enter Rate "
                validate={{ required: { value: false } }}
                type="Number"
                className="form-control"
              />
            </Col>
          </Row>

          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                HSC Code
              </label>
            </Col>
            <Col sm="6">
              <AvField
                name="HSCCode"
                label=""
                onChange={event => this.OnNameChange("HSCCode", event)}
                value={this.state.formData.HSCCode}
                placeholder=" HSCCode"
                errorMessage="Enter HSCCode"
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="country" className="col-form-label">
                GST Group Type
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="F_GSTGroupTypeMaster"
                label=""
                value={
                  this.state.formData.F_GSTGroupTypeMaster === null
                    ? "-1"
                    : this.state.formData.F_GSTGroupTypeMaster
                }
                errorMessage="Select GST Group type"
                validate={{ required: { value: false } }}
                type="select"
                className="form-select"
              >
                <option value={-1} defaultValue label={"Select"} />
                {this.state.GST
                  ? this.state.GST.map((option, key) => (
                      <option
                        key={option.Id}
                        value={option.Id}
                        label={option.Name}
                      />
                    ))
                  : null}
              </AvField>
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="country" className="col-form-label">
                Type
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="Type"
                label=""
                onChange={this.OnTypeChange}
                value={
                  this.state.formData.Type === null
                    ? "-1"
                    : this.state.formData.Type
                }
                errorMessage="Selecttype"
                validate={{ required: { value: false } }}
                type="select"
                className="form-select"
              >
                <option value={0} defaultValue label={"Select"} />
                <option value={1} defaultValue label={"GST"} />
                <option value={2} defaultValue label={"Others"} />
              </AvField>
            </Col>
            {GSTType}
          </Row>

          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="country" className="col-form-label">
                Calculation
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="Calculation"
                label=""
                value={
                  this.state.formData.Calculation === null
                    ? "-1"
                    : this.state.formData.Calculation
                }
                errorMessage="Select Calculation"
                validate={{ required: { value: false } }}
                type="select"
                className="form-select"
              >
                <option value={0} defaultValue label={"Select"} />
                <option value={1} defaultValue label={"Cumulative"} />
                <option value={2} defaultValue label={"OnGrossTotal"} />
                <option value={3} defaultValue label={"Manual"} />
              </AvField>
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="country" className="col-form-label">
                Nature
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="Nature"
                label=""
                value={
                  this.state.formData.Nature === null
                    ? "-1"
                    : this.state.formData.Nature
                }
                errorMessage="Select Nature"
                validate={{ required: { value: false } }}
                type="select"
                className="form-select"
              >
                <option value={0} defaultValue label={"Select"} />
                <option value={1} defaultValue label={"Add"} />
                <option value={2} defaultValue label={"Less"} />
              </AvField>
            </Col>
          </Row>
          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="country" className="col-form-label">
                Round Off Sales
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="RoundOffSales"
                label=""
                value={
                  this.state.formData.RoundOffSales === null
                    ? "-1"
                    : this.state.formData.RoundOffSales
                }
                errorMessage="Select Round Off Sales"
                validate={{ required: { value: false } }}
                type="select"
                className="form-select"
              >
                <option value={0} defaultValue label={"Select"} />
                <option value={1} defaultValue label={"Yes"} />
                <option value={2} defaultValue label={"No"} />
              </AvField>
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="country" className="col-form-label">
                Round Off Purchase
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="RoundOffPurchase"
                label=""
                value={
                  this.state.formData.RoundOffPurchase === null
                    ? "-1"
                    : this.state.formData.RoundOffPurchase
                }
                errorMessage="Select Round Off Purchase"
                validate={{ required: { value: false } }}
                type="select"
                className="form-select"
              >
                <option value={0} defaultValue label={"Select"} />
                <option value={1} defaultValue label={"Yes"} />
                <option value={2} defaultValue label={"No"} />
              </AvField>
            </Col>
          </Row>
        </>
      );
    else
      return (
        <>
          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Name
              </label>
            </Col>
            <Col sm="4">
              <AvField
                name="Name"
                label=""
                onChange={event => this.OnNameChange("Name", event)}
                value={
                  this.state.formData.Name === null
                    ? ""
                    : this.state.formData.Name
                }
                placeholder=" Name"
                errorMessage="Enter Name"
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Alias
              </label>
            </Col>
            <Col sm="4">
              <AvField
                name="Alias"
                label=""
                onChange={event => this.OnNameChange("Alias", event)}
                value={this.state.formData.Alias}
                placeholder="Alias"
                errorMessage="Enter Alias"
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>
          </Row>

          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                PAN NO.
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="PANNo"
                label=""
                value={this.state.formData.PANNo}
                placeholder="Pan no."
                errorMessage="Enter Pan no."
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                GST NO.
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="GSTNo"
                onChange={event => this.OnNameChange("GSTNo", event)}
                label=""
                value={this.state.GSTIN}
                placeholder="GstNo."
                errorMessage="Enter Gst No."
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>
          </Row>

          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Phone No.
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="Phone"
                onChange={event => this.OnNameChange("PhoneNo", event)}
                label=""
                value={this.state.PhoneNo}
                placeholder="Phone no."
                errorMessage="Enter Phone no."
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Mobile
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="Mobile"
                onChange={event => this.OnNameChange("Mobile", event)}
                label=""
                value={this.state.Mobile}
                placeholder="Mobile "
                errorMessage="Enter Mobile No."
                validate={{ required: { value: false } }}
                type="Number"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Email
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="Email"
                onChange={event => this.OnNameChange("Email", event)}
                label=""
                value={this.state.Email}
                placeholder="Email "
                errorMessage="Enter Email"
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>
          </Row>

          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Address 1
              </label>
            </Col>
            <Col sm="2">
              <textarea
                name="Address1"
                rows={3}
                cols={50}
                onChange={event => this.OnNameChange("Address1", event)}
                label=""
                value={this.state.Address1}
                placeholder="Address 1."
                errorMessage="Enter Address1."
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Address 2
              </label>
            </Col>
            <Col sm="2">
              <textarea
                rows={3}
                cols={50}
                name="Address2"
                label=""
                onChange={event => this.OnNameChange("Address2", event)}
                value={this.state.Address2}
                placeholder="Address 2."
                errorMessage="Enter Address2."
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Zip
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="Zip"
                label=""
                onChange={event => this.OnNameChange("Zip", event)}
                value={this.state.ZIP}
                placeholder="0"
                errorMessage="Enter Zip"
                validate={{ required: { value: false } }}
                type="Number"
                className="form-control"
              />
            </Col>
          </Row>

          <Row>
           

            <Col sm="2" className="mb-3">
              <label htmlFor="country" className="col-form-label">
                State
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="F_StateMaster"
                label=""
                onChange={event => this.OnNameChange("State", event)}
                value={this.state.StateId}
                errorMessage="Select State"
                validate={{ required: { value: false } }}
                type="select"
                className="form-select"
              >
                <option value={-1} defaultValue label={"Select"} />
                {this.state.State
                  ? this.state.State.map((option, key) => (
                      <option
                        key={option.Id}
                        value={option.Id}
                        label={option.Name}
                      />
                    ))
                  : null}
              </AvField>
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="country" className="col-form-label">
                City
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="F_CityMaster"
                label=""
                onChange={event => this.OnNameChange("City", event)}
                value={this.state.CityId}
                errorMessage="Select City"
                validate={{ required: { value: false } }}
                type="select"
                className="form-select"
              >
                <option value={-1} defaultValue label={"Select"} />
                {this.state.City
                  ? this.state.City.map((option, key) => (
                      <option
                        key={option.Id}
                        value={option.Id}
                        label={option.Name}
                      />
                    ))
                  : null}
              </AvField>
            </Col>
          </Row>

          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Area
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="Area"
                label=""
                value={this.state.formData.Area}
                placeholder="Area."
                errorMessage="Enter Area"
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>

            {/* <Col sm="2" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">Taxation Nature</label>
                                        </Col>
                                        <Col sm="2">
                                          <AvField name="TaxAddLess" label="" value={this.state.formData.TaxAddLess === null ? '-1'   : this.state.formData.TaxAddLess} errorMessage="Select Taxation Nature" validate={{ required: { value: false } }} type="select" className="form-select" >
                                            <option value={0} defaultValue label={"Select"} />
                                            <option value={1} defaultValue label={"Add"} />
                                            <option value={2} defaultValue label={"Less"} />
                                           
                                          </AvField>
                                        </Col>                                                                              */}
          </Row>

          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Contact Person 1
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="ContactPerson1"
                label=""
                value={this.state.formData.ContactPerson1}
                placeholder="Contact Person 1"
                errorMessage="Enter CONTACT "
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                {" "}
                Email
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="ContactPerson1_Email"
                label=""
                value={this.state.formData.ContactPerson1_Email}
                placeholder="Contact Person 1 Email "
                errorMessage="Enter Contact Person 1 Email"
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                {" "}
                Mobile
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="ContactPerson1_Mobile"
                label=""
                value={this.state.formData.ContactPerson1_Mobile}
                placeholder="Contact Person 1 Mobile "
                errorMessage="Enter Contact Person 1 Mobile No."
                validate={{ required: { value: false } }}
                type="Number"
                className="form-control"
              />
            </Col>
          </Row>

          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Contact Person 2
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="ContactPerson2"
                label=""
                value={this.state.formData.ContactPerson2}
                placeholder="Contact Person 2"
                errorMessage="Enter CONTACT "
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                {" "}
                Email
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="ContactPerson2_Email"
                label=""
                value={this.state.formData.ContactPerson2_Email}
                placeholder="Contact Person 2 Email "
                errorMessage="Enter Contact Person 2 Email"
                validate={{ required: { value: false } }}
                type="Text"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                {" "}
                Mobile
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="ContactPerson2_Mobile"
                label=""
                value={this.state.formData.ContactPerson2_Mobile}
                placeholder="Contact Person 2 Mobile "
                errorMessage="Enter Contact Person 2 Mobile No."
                validate={{ required: { value: false } }}
                type="Number"
                className="form-control"
              />
            </Col>
          </Row>

          <Row>
            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Credit Days
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="CrDays"
                label=""
                value={this.state.formData.CrDays}
                placeholder="0"
                errorMessage="Enter Credit Days "
                validate={{ required: { value: false } }}
                type="Number"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                Credit Limit
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="CreditLimit"
                label=""
                value={this.state.formData.CreditLimit}
                placeholder="0"
                errorMessage="Enter Credit Limit "
                validate={{ required: { value: false } }}
                type="Number"
                className="form-control"
              />
            </Col>

            <Col sm="2" className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                IsOwnState
              </label>
            </Col>
            <Col sm="2">
              <AvField
                name="IsOwnState"
                label=""
                checked={this.state.formData.IsOwnState}
                type="checkbox"
                className="form-control"
              />
            </Col>
          </Row>
        </>
      );
  }

  OnTypeChange = event => {
    var Type = event.target.value;
    if (Type == 1) {
      this.setState({ ShowGstType: true });
    } else {
      this.setState({ ShowGstType: false });
    }
  };

  OnNameChange = (Name, event) => {
    if (Name == "Name") {
      var value = event.target.value;
      this.setState({ Name: value });
    } else if (Name == "Alias") {
      var value = event.target.value;
      this.setState({ Alias: value });
    } else if (Name == "GSTNo") {
      var value = event.target.value;
      this.setState({ GSTIN: value });
    } else if (Name == "PhoneNo") {
      var value = event.target.value;
      this.setState({ PhoneNo: value });
    } else if (Name == "Mobile") {
      var value = event.target.value;
      this.setState({ Mobile: value });
    } else if (Name == "Email") {
      var value = event.target.value;
      this.setState({ Email: value });
    } else if (Name == "Address1") {
      var value = event.target.value;
      this.setState({ Address1: value });
    } else if (Name == "Address2") {
      var value = event.target.value;
      this.setState({ Address2: value });
    } else if (Name == "Zip") {
      var value = event.target.value;
      this.setState({ ZIP: value });
    } else if (Name == "Country") {
      var value = event.target.value;
      this.setState({ CountryId: value });
    } else if (Name == "State") {
      var value = event.target.value;
      Fn_FillListData(
        this.obj,
        "City",
        API_WEB_URLS.MASTER + "/0/token/City/F_StateMaster/" + value
      );
      this.setState({ StateId: value });
    } else if (Name == "City") {
      var value = event.target.value;
      this.setState({ CityId: value });
    } else if (Name == "HSCCode") {
      var value = event.target.value;
      this.setState({ HSCCode: value });
    }
  };

  OnledgerChange = event => {
    var Ledger = event.target.value;
    this.setState({ LedgerGroupIds: Ledger });
    Fn_FillListData(
      this.obj,
      "LedgerGroupId",
      API_WEB_URLS.MASTER + "/0/token/LedgerGroup/Id/" + Ledger
    );
  };

  OnledgerChangeEdit() {
    const { id } = this.props.match.params;
    Fn_FillListData(
      this.obj,
      "LedgerGroupId",
      API_WEB_URLS.MASTER + "/0/token/LedgerGroupIdOnEdit/Id/" + id
    );
  }

  render() {
    const JoinProduct = [
      {
        id: 1,
        JoinDate: "01/09/2022",
        JoinType: "Join",
        LeftDate: "__/__/____",
      },
    ];

    const LedgerType = this.Ledger();

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 1,
                            })}
                          ></NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 2,
                            })}
                          ></NavItem>

                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 3,
                            })}
                          ></NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 4,
                            })}
                          ></NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <TabContent
                            activeTab={this.state.activeTab}
                            className="body"
                          >
                            <TabPane tabId={1}>
                              <Row>
                                <Col lg="12">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4"></h4>
                                      <Row>
                                        <Col sm="2" className="mb-3">
                                          <label
                                            htmlFor="country"
                                            className="col-form-label"
                                          >
                                            LedgerGroup
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="F_LedgerGroupMaster"
                                            label=""
                                            onChange={this.OnledgerChange}
                                            value={
                                              this.state.formData
                                                .F_LedgerGroupMaster === null
                                                ? "-1"
                                                : this.state.formData
                                                    .F_LedgerGroupMaster
                                            }
                                            errorMessage="Select Ledger Group"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.LedgerGroup
                                              ? this.state.LedgerGroup.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                        {LedgerType}
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                            >
                              Save
                            </Button>
                            &nbsp;
                            <Button
                              type="button"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                              onClick={this.btnGoBack_onClick}
                            >
                              Go Back
                            </Button>
                            &nbsp;
                            <Button
                              type="button"
                              color="danger"
                              className="mr-1 waves-effect waves-light"
                              onClick={this.btnDelete}
                            >
                              DELETE
                            </Button>
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_Ledger);
