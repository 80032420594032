import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_ExportExcel,
} from "../../store/functions";


class Excel_Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      formData:{},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      SearchKeyArray:[],
      currentPage : 1,
      CheckedArray : [],
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "City";
    this.modalTitle = "Company Details";
    this.rtPage_Add = "AddCity";
    this.rtPage_Edit = "EditCity";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/ExcelGroup";
    this.API_URL_SAVE = API_WEB_URLS.ExcelGroup + "/0/token";
    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchKey = this.handleSearchKey.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.CheckBoxChange = this.CheckBoxChange.bind(this);
    this.CheckAllChange = this.CheckAllChange.bind(this);
    this.Clear = this.Clear.bind(this);
    this.HandleNameChange = this.HandleNameChange.bind(this);
  }
  handleSearchChange(event) {
    const searchValue = event.target.value;

    // Convert the search value to lowercase for case-insensitive comparison
    const searchLowerValue = searchValue.toLowerCase();

    // Filter the data based on the search value
    const filteredData = this.state.gridData.filter(item => {
        const searchKeyValue = item[this.state.searchKey];
        return searchLowerValue == '' || searchKeyValue
    });
    
    // Update the state with the filtered data
    this.setState({
        filteredData,
        currentPage : 1
    });
}
CheckAllChange(event){
  this.setState({
    CheckedArray : []
  },()=>{
    if(event.target.checked){
      const array = [...this.state.gridData]
      const ids = array.map(item => item.Id);
          this.setState({
            CheckedArray : ids
          })
    }
    

  })
}
CheckBoxChange(Id,event){
  
  if(event.target.checked){
    const arr = [...this.state.CheckedArray]

  arr.push(Id);
  this.setState({
    CheckedArray : arr
    });
  }
  else{
    const arr = [...this.state.CheckedArray]
    const filteredArray = arr.filter(number => number !== Id);
    console.log(filteredArray);
    this.setState({
      CheckedArray : filteredArray
      });
  }

    
}
handleSearchChange(event) {
    const searchValue = event.target.value;

    // Filter the data based on the search value
    const filteredData = this.state.gridData.filter(item => {
        // Convert the search value to lowercase for case-insensitive comparison
        const searchLowerValue = searchValue.toLowerCase();

        // Check if the search value is empty
        if (searchLowerValue === '') {
            return true; // Return true to include all items when search value is empty
        }

        // Perform string comparison for string values
        if (typeof item[this.state.searchKey] === 'string') {
            const searchKeyValue = item[this.state.searchKey].toLowerCase();
            return searchKeyValue.startsWith(searchLowerValue);
        }

        // Perform numeric comparison for numeric values
        if (typeof item[this.state.searchKey] === 'number') {
            const searchNumericValue = parseFloat(searchValue);
            return item[this.state.searchKey] === searchNumericValue;
        }

        // For other data types, return false to exclude them from the filtered data
        return false;
    });

    // Update the state with the filtered data
    this.setState({
        filteredData,
        currentPage: 1
    });
}

Clear(){
  let vformData = new FormData();
  vformData.append("Id",  1);
  vformData.append("Name", this.state.Name);
  vformData.append("Ids", 'nothing needed');

  Fn_GetReport(
    this.obj,
    { arguList: { id: 0, formData: vformData } },
    this.API_URL_SAVE,
    "ExcelData",
    true
  );
}
HandleNameChange(event){
const {value} = event.target
this.setState({
  Name : value
})
}

  handleSearchKey(item){
    this.setState({ searchKey: item },
      ()=>{
        console.log(this.state.gridData);
      }
    );
   
  }
  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };
  componentDidMount() {
  const obj = JSON.parse(localStorage.getItem("authUser"));
    Fn_FillListData(this.obj, "gridDataSearch", this.API_URL + "/Id/0" );
  }

  btnSave_onClick(event, formData){
    const array = [...this.state.CheckedArray]
    const string = array.join(',');

    let vformData = new FormData();
    vformData.append("Id",  0);
    vformData.append("Name", this.state.Name);
    vformData.append("Ids", string);

    Fn_ExportExcel(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      this.API_URL_SAVE,
      this.state.Name,
      true
    );
  }
  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }
  btnEdit_onClick(formData) {
    this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  renderGridHeader() {
    return (
      <>
        <th>Name</th>
        
       
      </>
    );
  }
  renderGridBody(formData) {
    return (
      <>
         <td>{formData.Name}</td>
         
        
      </>
    );
  }
  renderModalBody(selectedFormData) {
    return (
      <>
        <p className="mb-4">
        
        </p>
      </>
    );
  }
  render() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return (
      <div className="page-content">
      <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="wizard clearfix">
                  <div className="content clearfix">
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={this.btnSave_onClick}
                    >
                      <Row>
                      

                        <Col sm="1" className="mb-3">
                          <label htmlFor="tilldatee" className="col-form-label">
                            {" "}
                            Excel Name
                          </label>
                        </Col>
                        <Col sm="3" className="mb-3">
                        <AvField
                            name="Name"
                            label=""
                            value={
                              this.state.Name== undefined
                                ? ''
                                : this.state.Name
                            }
                            onChange={this.HandleNameChange}
                            placeholder="From Name"
                            errorMessage="Select Name "
                            validate={{ required: { value: true } }}
                            type="text"
                            className="form-control"
                          />
                        </Col>
                     

                        <Col sm="3" className="mb-3">
                          <Button
                            type="submit"
                            color="primary"
                            className="mr-1 waves-effect waves-light"
                          >
                            {" "}
                            Export{" "}
                          </Button>

                          <Button
                            type="button"
                            color="primary"
                            className="mr-1 waves-effect waves-light"
                            onClick={this.Clear}
                          >
                            {" "}
                            Clear{" "}
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      <RCDisplayPage
        //page header paramaters
        SearchKeyArray ={this.state.SearchKeyArray}
    
        currentPage={this.state.currentPage}
        searchKey ={this.state.searchKey}
        Isbreadcrumb = {false}
        // breadCrumbTitle={this.breadCrumbTitle}
        // breadcrumbItem={this.breadCrumbItem}
        obj={this.obj}
        //column paramaters
        isSearchBox={true}
        isSNo={false}
        isCheckBox={true}
        isViewDetails={false}
        //grid paramaters
        gridData={this.state.filteredData}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        handleSearchKey ={this.handleSearchKey}
        handleSearchChange ={this.handleSearchChange}
        handlePageChange={this.handlePageChange}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={  false}
        isDelete={ false}
        isPagination={true}
        CheckedArray = {this.state.CheckedArray}
        CheckBoxChange={this.CheckBoxChange}
        CheckAllChange={this.CheckAllChange}
      ></RCDisplayPage>
      </div>
    );
  }
}
export default compose(container)(Excel_Group);
