import React ,{useState , useRef} from "react";
import TableRows from "./TableRow";
import TableRows_new from "./TableRow_new";
import TableRows_pur from "./TableRow_pur";
function AddDeleteTableRows_Pur({ itemName , ply , Mrp, calculationtype ,GroupMaster, data , add , edit , deleter , plyquantity, TaxName, CustomerId, IsStockIn,UnitMaster}){



   
  


 

   






    return (
      <div className="table-responsive">
        <table className="" style={{ padding: "0", marginBottom: "10px" }}>
          <thead>
            <tr>
              <th>Sr.</th>
            
              <th>Code No.</th>
              <th>Group Name</th>
              <th>Item Name</th>
              <th>Unit</th>
              <th>Qty</th>
              {IsStockIn ? " " : <>
              <th>Rate</th>
              <th>Disc</th>
              <th>Amount</th>
              </>}
            
        
              <th>Del</th>
              <th>Add</th>
              {/* <th><button type="button" className="btn btn-outline-success" onClick={addTableRows} >+</button></th> */}
            </tr>
          </thead>
          <tbody>
            <TableRows_pur
              rowsData={data}
              deleteTableRows={deleter}
              handleChange={edit}
              addTableRows={add}
              itemName= {itemName}
              GroupMaster={GroupMaster}
              UnitMaster={UnitMaster}
              IsStockIn={IsStockIn}
            />
          </tbody>
        </table>
      </div>
    );
}
export default AddDeleteTableRows_Pur