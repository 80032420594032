import React, { Component } from "react";

//Import Breadcrumb

//Constants
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
} from "../../store/functions";
import RCDisplayPage from "components/Common/RCDisplayPage";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
class Report_InvoiceDetailedReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      formData: {F_GroupMaster : 0},
      PaymentType: [
        { Id: 1, Name: "Cash" },
        { Id: 2, Name: "Credit" },
        { Id: 3, Name: "Bank" },
      ],
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      SearchKeyArray: [],
      currentPage: 1,
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Group";
    this.modalTitle = "Group Details";
    this.rtPage_Add = "AddGroup";
    this.rtPage_Edit = "EditGroup";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Group";
    this.API_URL_SAVE = API_WEB_URLS.InvoiceDetailed + "/0/token";
    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchKey = this.handleSearchKey.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  handleSearchChange(event) {
    const searchValue = event.target.value;

    // Convert the search value to lowercase for case-insensitive comparison
    const searchLowerValue = searchValue.toLowerCase();

    // Filter the data based on the search value
    const filteredData = this.state.gridData.filter(item => {
      const searchKeyValue = item[this.state.searchKey];
      return searchLowerValue == "" || searchKeyValue;
    });

    // Update the state with the filtered data
    this.setState({
      filteredData,
      currentPage: 1,
    });
  }
  handleSearchChange(event) {
    const searchValue = event.target.value;

    // Filter the data based on the search value
    const filteredData = this.state.gridData.filter(item => {
      // Convert the search value to lowercase for case-insensitive comparison
      const searchLowerValue = searchValue.toLowerCase();

      // Check if the search value is empty
      if (searchLowerValue === "") {
        return true; // Return true to include all items when search value is empty
      }

      // Perform string comparison for string values
      if (typeof item[this.state.searchKey] === "string") {
        const searchKeyValue = item[this.state.searchKey].toLowerCase();
        return searchKeyValue.startsWith(searchLowerValue);
      }

      // Perform numeric comparison for numeric values
      if (typeof item[this.state.searchKey] === "number") {
        const searchNumericValue = parseFloat(searchValue);
        return item[this.state.searchKey] === searchNumericValue;
      }

      // For other data types, return false to exclude them from the filtered data
      return false;
    });

    // Update the state with the filtered data
    this.setState({
      filteredData,
      currentPage: 1,
    });
  }

  handleSearchKey(item) {
    this.setState({ searchKey: item }, () => {
      console.log(this.state.gridData);
    });
  }
  handlePageChange = pageNumber => {
    this.setState({ currentPage: pageNumber });
  };

  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });
    Fn_FillListData(this.obj, "Group", this.API_URL + "/Id/0" );
  }

  btnSave_onClick(event, formData) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    this.setState({ FromDate: formData.FromDate });

    let vformData = new FormData();
    vformData.append("Id", 0);
    vformData.append("FromDate", formData.FromDate);
    vformData.append("ToDate", formData.ToDate);

    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      this.API_URL_SAVE,
      "gridDataSearch",
      true
    );
  }

  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }
  btnEdit_onClick(formData) {
    this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  renderGridHeader() {
    return (
      <>
        <th>InvoiceDate</th>
        <th>InvoiceId</th>
        <th>PartyName</th>
        <th>ItemName</th>
        <th>Qty</th>
        <th>Rate</th>
        <th>Amount</th>
      </>
    );
  }
  renderGridBody(formData) {
    return (
      <>
        <td> {formData.InvoiceDate} </td>
        <td>{formData.InvoiceId}</td>
        <td  >{formData.PartyName}</td>
        <td >{formData.ItemName}</td>
        <td >{formData.Qty}</td>
        <td >{formData.Rate}</td>
        <td >{formData.Amount}</td>
      </>
    );
  }
  //
  renderModalBody(selectedFormData) {
    return (
      <>
        <p className="mb-4"></p>
      </>
    );
  }
  render() {
    return (
      <div className="page-content">
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="wizard clearfix">
                  <div className="content clearfix">
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={this.btnSave_onClick}
                    >
                      <Row>
                        {/* <Col sm="1" className="mb-3">
                          <label
                            htmlFor="F_GroupMaster"
                            className="col-form-label"
                          >
                            {" "}
                            Group
                          </label>
                        </Col>
                        <Col sm="3">
                          <AvField
                            name="F_GroupMaster"
                            label=""
                            value={
                              this.state.formData.F_GroupMaster === null
                                ? 0
                                : this.state.formData.F_GroupMaster
                            }
                            type="select"
                            className="form-select"
                          >
                            <option
                              value={0}
                              defaultValue
                              label={"All"}
                            />
                            {this.state.Group
                              ? this.state.Group.map((option, key) => (
                                  <option
                                    key={option.Id}
                                    value={option.Id}
                                    label={option.Name}
                                  />
                                ))
                              : null}
                          </AvField>
                        </Col> */}

                        <Col sm="1" className="mb-3">
                          <label htmlFor="tilldatee" className="col-form-label">
                            {" "}
                            From Date
                          </label>
                        </Col>
                        <Col sm="3" className="mb-3">
                          <AvField
                            name="FromDate"
                            label=""
                            value={
                              this.state.formData.FromDate == undefined
                                ? getCurrentDate()
                                : this.state.formData.FromDate
                            }
                            placeholder="From Date"
                            errorMessage="Select Date "
                            validate={{ required: { value: true } }}
                            type="date"
                            className="form-control"
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <label htmlFor="To Date" className="col-form-label">
                            {" "}
                            To Date
                          </label>
                        </Col>
                        <Col sm="3" className="mb-3">
                          <AvField
                            name="ToDate"
                            label=""
                            value={
                              this.state.formData.ToDate == undefined
                                ? getCurrentDate()
                                : this.state.formData.ToDate
                            }
                            placeholder="From ToDate"
                            errorMessage="Select ToDate "
                            validate={{ required: { value: true } }}
                            type="date"
                            className="form-control"
                          />
                        </Col>

                        <Col sm="3" className="mb-3">
                          <Button
                            type="submit"
                            color="primary"
                            className="mr-1 waves-effect waves-light"
                          >
                            {" "}
                            View{" "}
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <RCDisplayPage
          //page header paramaters
          SearchKeyArray={this.state.SearchKeyArray}
          currentPage={this.state.currentPage}
          searchKey={this.state.searchKey}
          Isbreadcrumb={true}
          breadCrumbTitle={this.breadCrumbTitle}
          breadcrumbItem={this.breadCrumbItem}
          obj={this.obj}
          //column paramaters
          isSearchBox={true}
          isSNo={false}
          isCheckBox={false}
          isViewDetails={false}
          //grid paramaters
          gridData={this.state.filteredData}
          gridHeader={this.renderGridHeader}
          gridBody={this.renderGridBody}
          handleSearchKey={this.handleSearchKey}
          handleSearchChange={this.handleSearchChange}
          handlePageChange={this.handlePageChange}
          btnAdd_onClick={this.btnAdd_onClick}
          btnEdit_onClick={this.btnEdit_onClick}
          //delete link parameters
          confirm_alert={this.state.confirm_alert}
          success_dlg={this.state.success_dlg}
          dynamic_title={this.state.dynamic_title}
          dynamic_description={this.state.dynamic_description}
          toggleDeleteConfirm={toggleDeleteConfirm}
          toggleDeleteSuccess={toggleDeleteSuccess}
          btnDelete_onClick={this.btnDelete_onClick}
          //modal paramaters
          isOpenModal={this.state.modal}
          modalTitle={this.modalTitle}
          selectedFormData={this.state.selectedFormData}
          modalBody={this.renderModalBody}
          togglemodal={togglemodal}
          //user rights
          isAdd={false}
          isEdit={false}
          isDelete={false}
          isPagination={true}
        ></RCDisplayPage>
      </div>
    );
  }
}
export default compose(container)(Report_InvoiceDetailedReport);
