import React, { Component } from "react";

//Import Breadcrumb
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
} from "../../store/functions";
import "./datatables.scss"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';




class pageList_Ledger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      page: 1,
      sizePerPage: 10,
      productData: []
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Ledger";
    this.modalTitle = "Company Details";
    this.rtPage_Add = "AddLedger";
    this.rtPage_Edit = "EditLedger";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Ledger";

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }
  handleKeyDown = event => {
    switch (event.key) {
      case "F1":
        event.preventDefault();
        this.props.history.push("/dashboard");
        break;
      default:
        break;
    }
  };
  componentDidMount() {

    document.addEventListener("keydown", this.handleKeyDown);
    
    const obj = JSON.parse(localStorage.getItem("authUser"));
     Fn_FillListData(this.obj, "gridData", this.API_URL + "/Id/0" );
  }
  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }
  btnEdit_onClick(Id) {
    this.props.history.push(`${this.rtPage_Edit}/${Id}`, {});
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  // renderGridHeader() {
  //   return (
  //     <>
  //       <th>Name</th>
  //       <th>Ledger Group Name</th>
  //       <th>Phone</th>
  //       <th>Email</th>
       
  //     </>
  //   );
  // }
  
  render() {

    const columns = [{
      dataField: 'Name',
      text: 'Name',
      sort: true
    }, {
      dataField: 'Ledger Group Name',
      text: 'LedgerGroupName',
      sort: true
    }, {
      dataField: 'Phone',
      text: 'Phone',
      sort: true
    }, {
      dataField: 'Email',
      text: 'Email',
      sort: true
    },
    {
      dataField: "Edit",
      text: "Edit",
      formatter: (cellContent, row) => {
        return (
          <button
          type="button"
            className="btn btn-danger btn-xs"
            onClick={() => this.btnEdit_onClick(row.Id)}
          >
            Edit
          </button>
        );
      },
    },];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.gridData, // replace later with size(customers),
      custom: true,
    }
    const selectRow = {
      mode: 'checkbox'
    }
    const { SearchBar } = Search;


    return (
      <div className="page-content">
<PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.gridData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.gridData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                    <Button type="button" onClick={this.btnAdd_onClick} color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Add New</Button>
                                 
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
      </div>
    );
  }
}
export default compose(container)(pageList_Ledger);